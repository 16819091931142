import { computed, effect } from "@angular/core";
import {
   patchState,
   signalStore,
   withComputed,
   withHooks,
   withMethods,
   withState,
} from "@ngrx/signals";
import {
   removeEntity,
   setAllEntities,
   setEntity,
   updateEntity,
   withEntities,
   type SelectEntityId,
} from "@ngrx/signals/entities";
import type {
   WorkRequestsSubmissionCount,
   WorkRequestSubmissionEntity,
} from "src/app/tasks/components/shared/services/work-request-submissions-api/work-request-submissions-api.models";

const selectId: SelectEntityId<WorkRequestSubmissionEntity> = (entity) =>
   entity.workRequestSubmissionId;

type RequestsState = {
   workRequestsCount: WorkRequestsSubmissionCount | undefined;
};

const initialState: RequestsState = {
   workRequestsCount: undefined,
};

/**
 * Entity Signal Store for Work Requests
 */
export const WorkRequestsStore = signalStore(
   { providedIn: "root" },

   withEntities<WorkRequestSubmissionEntity>(),

   /**
    * TODO: discuss whether we should opt for withState (better for page/feature state mgmt) vs withEntities (better for entity state mgmt)
    */
   withState(initialState),

   /**
    * Private methods: These serve as reusable reducer methods
    */
   withMethods((store) => ({
      _setAllRequests(workRequests: WorkRequestSubmissionEntity[]) {
         patchState(store, setAllEntities(workRequests, { selectId }));
      },
      _setRequest(workRequest: WorkRequestSubmissionEntity) {
         patchState(store, setEntity(workRequest, { selectId }));
      },
      _updateRequest(id: number, changes: Partial<WorkRequestSubmissionEntity>) {
         patchState(store, updateEntity({ id, changes }, { selectId }));
      },
      _deleteRequest(id: number) {
         patchState(store, removeEntity(id));
      },

      _setWorkRequestsCount(workRequestsCount: WorkRequestsSubmissionCount) {
         patchState(store, { workRequestsCount });
      },

      _onDestroy() {
         patchState(store, { ...initialState });
      },
   })),

   /**
    * Public methods: These serve as event handler methods - similar to actions in ngrx store
    */
   withMethods((store) => ({
      /**
       * loads the store with currently fetched requests - whenever page loads, paginates, searches, etc...
       */
      onLoadRequests(workRequests: WorkRequestSubmissionEntity[]) {
         store._setAllRequests(workRequests);
      },

      setWorkRequestsCount(workRequestsCount: WorkRequestsSubmissionCount) {
         store._setWorkRequestsCount(workRequestsCount);
      },

      onDestroy() {
         store._onDestroy();
      },

      /*
      onApproveRequest(id: number) {
         const APPROVED_STATUS = 99; // TODO: update to actual approved status ID
      },
      */

      // onDeclineRequest() {},

      // onDeleteRequest(id: number) { },

      // onEditRequest() {},
   })),

   /**
    * These serve as your selectors
    */
   withComputed((store) => ({
      workRequestsCount: computed(() => {
         const { workRequestsCount } = store;
         return workRequestsCount();
      }),
   })),

   /**
    * These serve as your effects - responsible for handling background processes and side effects.
    */
   withHooks({
      onInit() {
         effect(() => {
            // console.log("state changed in store!", getState(store));
         });
      },
   }),
);
