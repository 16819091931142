import { HttpClient } from "@angular/common/http";
import { effect, inject, Injectable } from "@angular/core";
import { map, type Observable, of, tap } from "rxjs";
import { AssetFieldDefinitionApiService } from "src/app/assets/services/asset-field-definition-api.service";
import {
   AssetFieldScopeType,
   type AssetTemplateField,
} from "src/app/assets/services/asset-field.types";
import type {
   AssetTemplateFieldFilters,
   AssetTemplateFieldsAPI,
} from "src/app/assets/services/asset-template-fields/asset-template-field-api.models";
import { TemplatesPageStore } from "src/app/assets/services/templatesPage.store";
import type {
   ListResponse,
   RequestOptions,
} from "src/app/shared/services/flannel-api-service";
import { assert } from "src/app/shared/utils/assert.utils";
import { environment } from "src/environments/environment";

/**
 * This service is used to edit templates that have been published. All changes are cached until the service is told to publish its cache.
 * At which point all changes are persisted in bulk to the API and a long running bulk update job is triggered.  If editing a draft
 * template, use the AssetTemplateFieldApiService.
 */
@Injectable({
   providedIn: "root",
})
export class EditAssetTemplateFieldApiService implements AssetTemplateFieldsAPI {
   private readonly http = inject(HttpClient);
   private readonly templatePageStore = inject(TemplatesPageStore);
   private readonly assetFieldDefinitionApiService = inject(
      AssetFieldDefinitionApiService,
   );

   private readonly baseUrl: string = `${environment.servicesURL()}/assets`;

   private templateID: number | undefined;
   private cache: {
      fieldsAdded: AssetTemplateField[]; // this property is used solely to track if any edits have been made to the template
      fieldsUpdated: AssetTemplateField[]; // this property is used solely to track if any edits have been made to the template
      fieldsDeleted: AssetTemplateField[];
   } = {
      fieldsAdded: [],
      fieldsUpdated: [],
      fieldsDeleted: [],
   };

   public constructor() {
      effect(() => {
         const templateID = this.templatePageStore.templateID();
         if (templateID !== this.templateID) {
            this.clear();
            this.templateID = templateID;
         }
      });
   }

   public clear(): void {
      this.cache = {
         fieldsAdded: [],
         fieldsUpdated: [],
         fieldsDeleted: [],
      };
   }

   public hasChanges(): boolean {
      return (
         this.cache.fieldsAdded.length > 0 ||
         this.cache.fieldsUpdated.length > 0 ||
         this.cache.fieldsDeleted.length > 0
      );
   }

   public publish(): Observable<AssetTemplateField[]> {
      if (!this.hasChanges()) {
         // If there are no changes, return the same response as the edit template API would return.
         return of(this.templatePageStore.fields());
      }

      return this.http.post<AssetTemplateField[]>(
         `${this.baseUrl}/template/${this.templateID}/edit`,
         this.mapEditBody(),
         {
            withCredentials: true,
         },
      );
   }

   protected mapEditBody(): {
      CurrentFieldIDs: number[];
      DeletedFieldIDs: number[];
      UpdatedAt: string | undefined;
   } {
      return {
         CurrentFieldIDs: [...this.templatePageStore.fields()]
            .sort((a, b) => a.viewOrder - b.viewOrder)
            .map((field) => field.fieldID),
         DeletedFieldIDs: this.cache.fieldsDeleted.map((field) => field.fieldID),
         UpdatedAt: this.templatePageStore.template()?.UpdatedAt,
      };
   }

   /* eslint-disable typescript/no-unused-vars -- this is necessary to match the API interface */
   public getList(
      requestOptions: Partial<RequestOptions<AssetTemplateFieldFilters>>,
   ): Observable<Partial<ListResponse<AssetTemplateField>>> {
      return of({
         data: this.templatePageStore.fields(),
         total: this.templatePageStore.fields().length,
      });
   }

   public createBulk(
      body: number[],
      requestOptions: Partial<RequestOptions<AssetTemplateFieldFilters>>,
   ): Observable<AssetTemplateField[]> {
      const templateID = this.templatePageStore.templateID();
      assert(templateID, "templateID is required");

      let viewOrder = this.templatePageStore.fields().length + 1;

      // Make sure no previously deleted fields are being added back to the template
      this.cache.fieldsDeleted = this.cache.fieldsDeleted.filter(
         (field) => !body.includes(field.fieldID),
      );

      return this.assetFieldDefinitionApiService
         .getList({
            filters: {
               scopeType: AssetFieldScopeType.Standardized,
            },
         })
         .pipe(
            map((response) => response.data ?? []),
            map((definitions) =>
               definitions.filter((definition) => body.includes(definition.fieldID)),
            ),
            map((definitions) =>
               // Convert the definitions to the AssetTemplateField format
               definitions.map((definition) => ({
                  ...definition,
                  templateID: templateID,
                  viewOrder: viewOrder++,
               })),
            ),
            tap((fields) => {
               this.cache.fieldsAdded.push(...fields);
            }),
         );
   }
   /* eslint-enable typescript/no-unused-vars */

   public patch(
      id: number,
      body: Partial<AssetTemplateField>,
      requestOptions: Partial<RequestOptions<AssetTemplateFieldFilters>>,
   ): Observable<AssetTemplateField> {
      assert(requestOptions.filters?.templateID, "templateID is required");

      let field = this.templatePageStore
         .fields()
         .find((current) => current.fieldID === id);
      if (!field) {
         throw new Error("Field not found");
      }

      field = { ...field, ...body };

      this.cache.fieldsUpdated.push({ ...field });

      return of(field);
   }

   public delete(
      id: number,
      requestOptions: Partial<RequestOptions<AssetTemplateFieldFilters>>,
   ): Observable<any> {
      assert(requestOptions.filters?.templateID, "templateID is required");

      const field = this.templatePageStore
         .fields()
         .find((current) => current.fieldID === id);
      if (!field) {
         throw new Error("Field not found");
      }

      const isNewlyAdded = this.cache.fieldsAdded.some(
         (addedField) => addedField.fieldID === id,
      );

      if (!isNewlyAdded) {
         this.cache.fieldsDeleted.push({ ...field });
      }

      this.cache.fieldsUpdated = this.cache.fieldsUpdated.filter(
         (updatedField) => updatedField.fieldID !== id,
      );
      this.cache.fieldsAdded = this.cache.fieldsAdded.filter(
         (addedField) => addedField.fieldID !== id,
      );

      return of({ ...field });
   }
}
