import { ChangeDetectionStrategy, Component, inject, input, signal } from "@angular/core";
import {
   BasicModalHeaderComponent,
   LimUiModalRef,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   type ModalResult,
} from "@limblecmms/lim-ui";
import { TranslationService } from "src/app/languages/translation/translation.service";
import { AlertService } from "src/app/shared/services/alert.service";
import { FeedbackSubmittedComponent } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/feedback/modal/feedback-submitted/feedback-submitted.component";
import { NegativeFormComponent } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/feedback/modal/negative-form/negative-form.component";
import { PositiveFormComponent } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/feedback/modal/positive-form/positive-form.component";
import type { PmBuilderFeedback } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/feedback/pm-builder-feedback";
import { PmBuilderFeedbackApiService } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/feedback/pm-builder-feedback-api.service";

@Component({
   selector: "lim-feedback-modal",
   templateUrl: "./feedback-modal.component.html",
   styleUrls: ["./feedback-modal.component.scss"],
   changeDetection: ChangeDetectionStrategy.OnPush,
   imports: [
      PositiveFormComponent,
      NegativeFormComponent,
      FeedbackSubmittedComponent,
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
   ],
})
export class FeedbackModalComponent implements ModalResult<undefined> {
   public readonly suggestionID = input.required<number>();
   public readonly isPositive = input.required<boolean>();

   public readonly modalRef = inject(LimUiModalRef<FeedbackModalComponent, undefined>);
   protected readonly i18n = inject(TranslationService).i18n;
   private readonly pmBuilderFeedbackApi = inject(PmBuilderFeedbackApiService);
   private readonly alertService = inject(AlertService);

   protected readonly submittedInternal = signal<boolean>(false);
   public readonly submitted = this.submittedInternal.asReadonly();

   public close(): void {
      this.modalRef.close();
   }

   public async handleFeedback(feedback: PmBuilderFeedback): Promise<void> {
      this.submittedInternal.set(true);
      await this.pmBuilderFeedbackApi.create(feedback).catch((error: unknown) => {
         this.alertService.addAlert(this.i18n().t("errorMsg"), "danger", 8000);
         console.error(error);
      });
   }
}
