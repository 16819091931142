import { Component, input } from "@angular/core";

@Component({
   selector: "custom-work-request-field",
   imports: [],
   templateUrl: "./custom-work-request-field.component.html",
   styleUrl: "./custom-work-request-field.component.scss",
})
export class CustomWorkRequestFieldComponent {
   title = input<string>("");
   value = input<string>("");
}
