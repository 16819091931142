<lim-ui-modal [showWatermark]="true">
   @if (field) {
      <lim-ui-basic-modal-header [title]="title" (closeModal)="dismiss()" />
      <lim-ui-modal-body>
         <lim-ui-info-panel>
            @let messageText =
               isStandardizedField
                  ? lang().EditAssetFieldForTemplateNote
                  : lang().EditAssetFieldAtLocationNote;
            <span class="message-area" [limbleHtml]="messageText"></span>
         </lim-ui-info-panel>
         <lim-ui-panel>
            <div class="panel-label">{{ lang().Field }} {{ lang().Name }}</div>

            <div
               class="updateFieldHolder"
               [(ngModel)]="fieldName"
               (afterEdit)="updateSuggestedFieldName(field)"
               limbleContentEditable
               stripHtml="true"
               disableEditor="true"
            ></div
         ></lim-ui-panel>
         <!-- Fields with the type 'File' or 'Picture' may have only one option:
               'AutomaticallyAddFieldToNewAssetsAtLocation', which is not available for standardized fields. -->
         <!-- To prevent this panel from being empty, it needs to be hidden entirely. -->
         <lim-ui-panel
            class="checkboxes"
            [class.hidden-panel]="
               isStandardizedField &&
               (field.fieldTypeID === AssetFieldTypeID.File ||
                  field.fieldTypeID === AssetFieldTypeID.Picture ||
                  field.fieldTypeID === AssetFieldTypeID.Video)
            "
         >
            @if (
               field.fieldTypeID == AssetFieldTypeID.Text ||
               field.fieldTypeID == AssetFieldTypeID.Number
            ) {
               <div class="checkbox-row">
                  <lim-ui-checkbox
                     [label]="lang().RequireValuesToBeUnique"
                     [(model)]="valueUnique"
                     (modelChange)="updateValueUnique()"
                     [dataLogLabel]="
                        'manageAssets-StoreAddCustomField-clickSettingsRequireUnique'
                     "
                  />
                  <lim-ui-icon
                     placement="top"
                     limUiTooltip="{{
                        lang().RequireValuesToBeUniqueTooltip +
                           ' ' +
                           lang().valuesUniqueTooltipExtra
                     }}"
                     icon="circleQuestionRegular"
                     iconSize="small"
                  />
               </div>
            }
            @if (showReplaceCheckbox) {
               <div class="update-children-container">
                  <lim-ui-checkbox
                     [model]="useSelectedChildrenOption"
                     (modelChange)="updateUseOption()"
                     [dataLogLabel]="
                        'manageAssets-StoreAddCustomField-clickSettingsUpdateChild'
                     "
                  />
                  <div class="number-field-children-update-option">
                     <div class="text-holder checkbox-row">
                        @if (field.fieldTypeID == AssetFieldTypeID.Number) {
                           <div>
                              <lim-ui-dropdown>
                                 <div class="blue clickable" button>
                                    {{ childrenOptionPhrases[selectedChildrenOption] }}
                                 </div>
                                 <div menu>
                                    @for (option of updateChildrenOptions; track option) {
                                       <lim-ui-dropdown-text-item
                                          [displayString]="childrenOptionPhrases[option]"
                                          (click)="setUpdateChildrenOption(option)"
                                       />
                                    }
                                 </div>
                              </lim-ui-dropdown>
                           </div>
                        } @else {
                           <span>{{ lang().Replace }}</span>
                        }
                        <div>{{ lang().UpdateFieldsInChildAssets }}</div>
                     </div>
                     @if (
                        field.fieldTypeID == AssetFieldTypeID.Text ||
                        field.fieldTypeID == AssetFieldTypeID.Number ||
                        field.fieldTypeID == AssetFieldTypeID.Dropdown
                     ) {
                        <lim-ui-icon
                           placement="top"
                           limUiTooltip="{{ lang().updateChildrenOptionsTooltip }}"
                           icon="circleQuestionRegular"
                           iconSize="small"
                        />
                     }
                  </div>
               </div>
            }
            @if (
               field.fieldTypeID !== AssetFieldTypeID.Picture &&
               field.fieldTypeID !== AssetFieldTypeID.File &&
               field.fieldTypeID !== AssetFieldTypeID.Video
            ) {
               <div class="checkbox-row">
                  <lim-ui-checkbox
                     [model]="!!field.displayOnTasks"
                     (modelChange)="updateDisplayOnTasks()"
                     [label]="lang().DisplayFieldInformationOnTasks"
                     [dataLogLabel]="
                        'manageAssets-StoreAddCustomField-clickSettingsDisplayOnTask'
                     "
                  />
                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     placement="top"
                     limUiTooltip="{{ lang().DisplayOnTasksTooltip }}"
                     iconSize="small"
                  />
               </div>
            }
            @if (!isStandardizedField) {
               <div class="checkbox-row">
                  <lim-ui-checkbox
                     [(model)]="isCustomDefault"
                     (modelChange)="updateCustomDefault()"
                     [label]="lang().AutomaticallyAddFieldToNewAssetsAtLocation"
                     [dataLogLabel]="
                        'manageAssets-StoreAddCustomField-clickSettingsAddToNewAssets'
                     "
                  />
               </div>
            }
         </lim-ui-panel>

         @if (field.fieldTypeID == AssetFieldTypeID.Dropdown) {
            <lim-ui-panel>
               <div class="panel-label d-flex">
                  <div>{{ lang().Field }} {{ lang().Options }}</div>
                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     placement="top"
                     limUiTooltip="{{ lang().fieldOptionsToolTip }}"
                     iconSize="small"
                  />
               </div>
               <div>
                  @if (options) {
                     <limble-tree-root
                        #tree
                        [data]="options"
                        [options]="treeOptions"
                        (treeDrop)="onMoveNode($event)"
                     />
                  }
               </div>
               <div>
                  <lim-ui-secondary-button
                     (click)="addDropdownOption()"
                     icon="plus"
                     [dataLogLabel]="
                        'manageAssets-storeAddCustomField-clickAddDropdownOption'
                     "
                  >
                     {{ lang().AddOption }}
                  </lim-ui-secondary-button>
               </div>
            </lim-ui-panel>
         }
         @if (field.fieldTypeID == AssetFieldTypeID.Date) {
            <lim-ui-panel>
               <div class="panel-label">
                  {{ lang().ReminderOptions }}
                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     placement="top"
                     limUiTooltip="{{ lang().fieldReminderToolTip }}"
                     iconSize="small"
                  />
               </div>
               <date-reminder
                  [field]="field"
                  reminder="dateReminder1"
                  type="asset"
                  [dataLogLabel]="
                     'manageAssets-storeAddCustomField-clickReminderCheckBox'
                  "
               />
               <date-reminder
                  [field]="field"
                  reminder="dateReminder2"
                  type="asset"
                  [dataLogLabel]="
                     'manageAssets-storeAddCustomField-clickReminderCheckBox'
                  "
               />
               <date-reminder
                  [field]="field"
                  reminder="dateReminder3"
                  type="asset"
                  [dataLogLabel]="
                     'manageAssets-storeAddCustomField-clickReminderCheckBox'
                  "
               />
               <div class="send-to-container">
                  <span>{{ lang().SendTo }}</span>
                  <div
                     (click)="changeFieldOwner(field)"
                     [attr.data-log]="
                        'manageAssets-storeAddCustomField-clickChangeSendTo'
                     "
                     limUiTooltip="{{ lang().RemindersWillBeSentToThisUserOrTeam }}"
                     placement="bottom"
                  >
                     @if (field.userID == 0 && field.profileID == 0) {
                        <div class="blue clickable">
                           {{ lang().Manager }}
                        </div>
                     }
                     @if (field.userID && field.userID > 0) {
                        <div class="blue clickable">
                           {{ fieldExtraInfo.userFirstName }}
                           {{ fieldExtraInfo.userLastName }}
                        </div>
                     }
                     @if (field.profileID && field.profileID > 0) {
                        <div class="blue clickable">
                           {{ fieldExtraInfo.profileDescription }}
                        </div>
                     }
                  </div>
               </div>
            </lim-ui-panel>
         }
         <lim-ui-panel>
            <div class="panel-label">
               {{ lang().DefaultBehaviors }}
            </div>
            <div class="checkbox-row">
               <lim-ui-checkbox
                  [model]="!field.viewableByTechFieldDefault"
                  (modelChange)="
                     updateFieldViewableByTechFieldDefault(
                        !field.viewableByTechFieldDefault
                     )
                  "
                  [label]="lang().HideField"
                  [tooltip]="lang().ChangeDefaultShowHideAssetColumnsTool"
                  [dataLogLabel]="
                     'manageAssets-StoreAddCustomField-clickSettingsHideField'
                  "
               />
            </div>
            <div class="checkbox-explanation">
               @if (!field.viewableByTechFieldDefault) {
                  <p class="lim-ui-fonts-caption">
                     {{ lang().FieldHiddenExplanation }}
                  </p>
               }
               @if (field.viewableByTechFieldDefault) {
                  <p class="lim-ui-fonts-caption">
                     {{ lang().FieldShownExplanation }}
                  </p>
               }
            </div>
            @if (isSuperUser) {
               <div>
                  <div class="checkbox-row">
                     <lim-ui-checkbox
                        [(model)]="field.lockedDefault"
                        (modelChange)="toggleFieldLockedDefault()"
                        [label]="lang().LockField"
                        [tooltip]="lang().LockFieldTooltip"
                        [dataLogLabel]="
                           'manageAssets-StoreAddCustomField-clickSettingsLockField'
                        "
                     />
                  </div>
                  <div class="checkbox-explanation">
                     @if (field.lockedDefault) {
                        <p class="lim-ui-fonts-caption">
                           {{ lang().LockedFieldExplanation }}
                        </p>
                     }
                     @if (!field.lockedDefault) {
                        <p class="lim-ui-fonts-caption">
                           {{ lang().UnlockedFieldExplanation }}
                        </p>
                     }
                  </div>
               </div>
            }
         </lim-ui-panel>
      </lim-ui-modal-body>
      <lim-ui-modal-footer>
         <div class="footer-wrapper">
            <!--             TODO:  just remove "&& !isStandardizedField" when  https://limble.atlassian.net/browse/DONKEY-1000 will be ready to go-->
            @if (!field.lockedDefault || (isSuperUser && !isStandardizedField)) {
               @let removeFieldDataLogText =
                  isStandardizedField
                     ? "assetTemplatesEditTemplateFlow-AnyStateFieldSettings-clickInitiateDeleteCustomField"
                     : "manageAssets-storeAddCustomField-clickDeleteCustomField";
               <lim-ui-danger-button
                  (click)="removeSuggestedField(field)"
                  [dataLogLabel]="removeFieldDataLogText"
               >
                  {{ lang().Delete }}
               </lim-ui-danger-button>
            }
         </div>
      </lim-ui-modal-footer>
   }
</lim-ui-modal>
