import { computed, inject, Injectable } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import type { AxiosResponse } from "axios/dist/axios";
import axios from "axios/dist/axios";
import Cookies from "js-cookie";
import moment from "moment";
import { BehaviorSubject, firstValueFrom, Subject } from "rxjs";
import { filter, first, shareReplay } from "rxjs/operators";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { PushNotificationService } from "src/app/mobile/push-notifications/push-notification.service";
import { UserflowService } from "src/app/onboarding/services/userflow.service";
import { BeamerService } from "src/app/shared/external-scripts/beamer/beamer.service";
import { ChurnZeroService } from "src/app/shared/external-scripts/churn-zero.service";
import { DatadogService } from "src/app/shared/external-scripts/datadog.service";
import { HeapService } from "src/app/shared/external-scripts/heap.service";
import { IntercomService } from "src/app/shared/external-scripts/intercom.service";
import { SurvicateService } from "src/app/shared/external-scripts/survicate.service";
import { AlertService } from "src/app/shared/services/alert.service";
import { EnvRoutesService } from "src/app/shared/services/envRoutes.service";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { RoutingService } from "src/app/shared/services/routing/routing.service";
import { UpdateService } from "src/app/shared/services/update/update.service";
import { WebsocketService } from "src/app/shared/services/websockets/websocket.service";
import { WhiteLabelService } from "src/app/shared/services/white-label/white-label.service";
import { isNullish } from "src/app/shared/utils/app.util";
import { CredService } from "src/app/users/services//creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";
import Version from "src/root/version.json";

@Injectable({ providedIn: "root" })
export class ManageLogin {
   public readonly ssoEnabled$ = new BehaviorSubject(null);
   private readonly userField = "system_processor"; //these names are specifically misleading
   private readonly passField = "limble_region"; //these names are specifically misleading
   private readonly window: any = window;
   private readonly axios = axios;
   public readonly updateCustomLogo$: Subject<string | null>;
   public showInactiveUsers;

   private readonly router = inject(Router);
   private readonly routingService = inject(RoutingService);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageUser = inject(ManageUser);
   private readonly alertService = inject(AlertService);
   private readonly manageLang = inject(ManageLang);
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageObservables = inject(ManageObservables);
   private readonly websocketService = inject(WebsocketService);
   private readonly credService = inject(CredService);
   private readonly envRoutesService = inject(EnvRoutesService);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly whiteLabelService = inject(WhiteLabelService);
   private readonly churnZeroService = inject(ChurnZeroService);
   private readonly datadogService = inject(DatadogService);
   private readonly intercomService = inject(IntercomService);
   private readonly beamerService = inject(BeamerService);
   private readonly heapService = inject(HeapService);
   private readonly survicateService = inject(SurvicateService);
   private readonly updateService = inject(UpdateService);
   private readonly userflowService = inject(UserflowService);
   private readonly pushNotificationService = inject(PushNotificationService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.createObservables();
      this.manageUser.currentUserInitialized$.subscribe(() => {
         this.datadogService.init({
            limbleVersion: Version.version ?? "",
         });
         this.userflowService.loadOrRefresh();
         this.heapService.setAccountProperties();
         this.intercomService.loadOrRefresh({ limbleVersion: Version.version ?? "" });
         this.heapService.identifyUser();
         this.beamerService.load();
         this.survicateService.load();
         this.churnZeroService.loadAndTrackLogin();
      });
      this.updateCustomLogo$ = new Subject();
      this.router.events
         .pipe(
            filter((event): event is NavigationStart => event instanceof NavigationStart),
            filter(
               () =>
                  this.manageUser.getCurrentUser() !== undefined &&
                  this.manageUser.getCurrentUser() !== "none",
            ),
         )
         .subscribe(() => {
            this.intercomService.loadOrRefresh({ limbleVersion: Version.version ?? "" });
            this.userflowService.loadOrRefresh();
         });
   }

   private createObservables(): void {
      this.manageObservables.createObservable("customerID");
      this.manageObservables.createObservable("featureSSO");
      this.manageObservables.createObservable("ssoEnabled");
   }

   public setLoginLocalStorage(options: { username?: string; password?: string }): void {
      if (options.username) {
         localStorage.setItem(this.userField, btoa(options.username));
      }
      if (options.password) {
         localStorage.setItem(this.passField, btoa(options.password));
      }
   }

   private setUIDCookie(): void {
      const date = new Date();
      date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
      Cookies.set("UID", this.manageUser.getCurrentUser().gUserID, {
         expires: date,
         path: "/",
      });
   }

   public async logout(): Promise<void> {
      await this.axios({
         method: "POST",
         url: "phpscripts/manageLogin.php",
         params: { action: "logout" },
         data: { sessionID: this.websocketService.getSessionID() },
      });
      Cookies.remove("UID", { path: "/" });
      /* 
         Shutting down intercom is necessary in order to remove the intercom cookie
         and prevent other users from potentially being able to view the previous user's
         intercom messages. See
         https://www.intercom.com/help/en/articles/16845-how-do-i-end-a-session
      */
      this.window.Intercom?.("shutdown");
      this.churnZeroService.unload();
      this.pushNotificationService.unregisterDevice();
      this.routeToAuthServer();
   }

   private async routeToAuthServer(): Promise<void> {
      const envRoutes = await this.envRoutesService.getRoutes();
      window.location.href = envRoutes.frontendAuthServer;
   }

   public async checkLogin() {
      const route = this.router.url;
      return this.axios({
         method: "POST",
         url: "phpscripts/manageLogin.php",
         params: { action: "checkLogin", route },
         data: {},
      }).then((answer) => {
         if (answer.data?.canaryRedirect !== undefined) {
            window.location.replace(answer.data.canaryRedirect);
            answer.data.success = false;
            answer.status = 202;
            return answer;
         }
         if (answer.data?.version) {
            this.updateService.checkVersions(answer.data.version);
         }
         const currentUser = answer?.data?.currentUser;
         if (
            answer.data.success !== true ||
            isNullish(currentUser) ||
            currentUser.success === false
         ) {
            setTimeout(() => {
               this.notLoggedIn();
            }, 100);
            return answer;
         }

         this.manageFeatureFlags.setFeatureFlags(currentUser);

         // This is to make sure that we don't load any extra data if we are on the Work Request portal page and not logged in.
         if (
            currentUser.workOrderUser == 1 &&
            (window.location.href.includes("problem") ||
               window.location.href.includes("check-work-requests"))
         ) {
            // Hit external user on the problem page. treat as if not logged in as external user
            this.notLoggedIn();
            return answer;
         }

         currentUser.profileLoc =
            this.manageUtil.processGetData(
               currentUser.profileLocHeaders,
               currentUser.profileLocData,
            ) || [];

         delete currentUser.profileLocHeaders;
         delete currentUser.profileLocData;

         this.credService.setCredentials(currentUser.credArr);
         delete currentUser.credArr;

         if (Object.keys(this.credService.getCredentials()).length === 0) {
            this.alertService.addAlert(this.lang().noRolesSSO, "danger", 60000);
         }

         this.manageObservables.updateObservable(
            "customerID",
            currentUser.userInfo.customerID,
         );

         this.manageObservables.updateObservable(
            "featureSSO",
            currentUser.userInfo.featureSSO,
         );

         this.ssoEnabled$.next(currentUser.userInfo.ssoEnabled);

         moment.updateLocale("en", {
            week: {
               dow: currentUser.userInfo.customerStartOfWorkWeek,
               doy: moment.localeData("en").firstDayOfYear(),
            },
         });

         if (!this.whiteLabelService.isValidCustomer(currentUser.userInfo.customerID)) {
            //they aren't valid so log them out.
            this.logout();
            this.alertService.addAlert(
               this.lang().PleaseUseTheCorrectDomain,
               "danger",
               10000,
            );
            return answer;
         }

         if (currentUser.workOrderUser == 1) {
            //   force route them to the view external task page
            if (
               window.location.href.includes("viewExternalTask") ||
               window.location.href.includes("externalPOWorkflowStep") ||
               window.location.href.includes("loggedInAsExternalUser") ||
               window.location.href.includes("thanks")
            ) {
               //test
               //they are at the correct place so we are good to go.
            } else {
               //they somehow got to a page they shouldn't be at so log them out.
               this.logout();
               return answer;
            }
         }

         currentUser.tags = currentUser.tags.replace(/\\'/g, "'");
         currentUser.tags = currentUser.tags.replace(/;/g, "");

         //sets preferences for things like what view they are on, how things are sorted, etc.
         try {
            currentUser.userInfo.userUIPreferences = JSON.parse(
               currentUser.userInfo.userUIPreferences,
            );
            currentUser.userInfo.filterAssetPartData = JSON.parse(
               currentUser.userInfo.filterAssetPartData,
            );
         } catch (error) {
            currentUser.userInfo.userUIPreferences = {};
            currentUser.userInfo.filterAssetPartData = {};
         }

         //if the user is inactive do something...
         if (currentUser.userInfo.userInternal != 1) {
            if (currentUser.userInfo.customerActive == 0) {
               //they can view billing so they must be a superuser.
               const routeNames = this.routingService.getAllRouteNames();
               if (
                  this.credService.checkCredGlobal(
                     this.credService.Permissions.ManageLocations,
                  )
               ) {
                  this.alertService.clearAllAlerts();
                  this.alertService.addAlert(
                     this.lang().WereSorryButYourLimbleCMMSSubscriptionIsInactive,
                     "danger",
                     10000,
                  );
                  if (!routeNames.includes("subscription")) {
                     this.router.navigate(["/subscription"]);
                  }
               } else {
                  if (!routeNames.includes("inactiveWarning")) {
                     this.router.navigate(["/inactiveWarning"]);
                  }
               }
            }
         }

         this.manageUser.setCurrentUser(currentUser);
         this.setUIDCookie(); //sets the customerID as a cookie so that if they are using it on different tabs in a browser I can detect changes and force people to be on the same limble account.

         this.manageLang.calcLang(currentUser);

         if (
            currentUser.userInfo.featureOnboardingSurvey == 1 &&
            !window.location.href.includes("onboardingManagement")
         ) {
            window.location.replace(`/onboardingManagement`);
         }

         return answer;
      });
   }

   private notLoggedIn(): void {
      this.manageUser.setCurrentUser("none");
      Cookies.remove("UID", { path: "/" });
      if (this.routingService.loginRequired()) {
         this.envRoutesService.getRoutes().then((envRoutes) => {
            window.location.href = envRoutes.frontendAuthServer;
         });
      }
   }
   public findStartingStateFromLogin(): void {
      const locations = this.manageLocation.getLocations();
      if (
         this.credService.checkCredAnywhere(
            this.credService.Permissions.ViewGlobalDashboard,
         )
      ) {
         //can view global dashboards
         this.router.navigate(["/dashboard"]);
         return;
      }
      if (
         this.credService.checkCredAnywhere(
            this.credService.Permissions.ViewCustomDashboards,
         )
      ) {
         //can view custom dashboards
         this.router.navigate(["/customDashboard", 0]);
         return;
      }
      if (
         locations[0] !== undefined &&
         (this.credService.isAuthorized(
            locations[0].locationID,
            this.credService.Permissions.ViewMyOpenTasks,
         ) ||
            this.credService.isAuthorized(
               locations[0].locationID,
               this.credService.Permissions.ViewAllOpenTasks,
            ))
      ) {
         //can view a location and can view tasks there
         this.router.navigate(["/tasks", locations[0].locationID]);
         return;
      }
      this.router.navigate(["/mobileDashboard"]); //catch all ;p most people shouldn't have them never been able to see anything.
   }

   public async manualResetPassword(
      oldPassword: string,
      newPassword1: string,
      newPassword2: string,
   ): Promise<AxiosResponse<any>> {
      return this.axios({
         method: "POST",
         url: "phpscripts/manageLogin.php",
         params: {
            action: "resetPassword",
         },
         data: {
            currentPW: oldPassword,
            pw1: newPassword1,
            pw2: newPassword2,
         },
      });
   }

   public async superuserManualResetPassword(
      userIDToReset: number,
      newPassword1: string,
      newPassword2: string,
   ): Promise<AxiosResponse<any>> {
      return this.axios({
         method: "POST",
         url: "phpscripts/manageLogin.php",
         params: {
            action: "superuserManualResetPassword",
         },
         data: {
            userIDToReset: userIDToReset,
            pw1: newPassword1,
            pw2: newPassword2,
         },
      });
   }

   public credCheckEditChecklist(task): boolean {
      if (
         this.credService.isAuthorized(
            task.locationID,
            this.credService.Permissions.EditAndCompleteOpenTasks,
         )
      ) {
         return true;
      }
      let belongsToMe = false;
      const currentUser = this.manageUser.getCurrentUser();

      if (task.userID == currentUser.gUserID) {
         belongsToMe = true;
      }

      for (const profile of currentUser.profileLoc) {
         if (
            profile.locationID == task.locationID &&
            profile.profileID == task.profileID
         ) {
            belongsToMe = true;
         }
      }

      if (belongsToMe) {
         if (
            this.credService.isAuthorized(
               task.locationID,
               this.credService.Permissions.EditAndCompleteMyOpenTasks,
            )
         ) {
            //belongs to me and I have the creds to complete my own tasks
            return true;
         }
      }

      return false;
   }

   public findCorrectDefaultState(): void {
      if (this.manageUser.getCurrentUser()?.userInfo !== undefined) {
         this.findStartingStateFromLogin();
      }
   }

   public async checkLocationIsValid(locationID: number): Promise<boolean> {
      await this.waitLocationRequestFinished();

      const currentUser = this.manageUser.getCurrentUser();
      if (!currentUser || currentUser === "none") {
         return false;
      }

      if (!this.manageLocation.getLocation(locationID)) {
         this.findStartingStateFromLogin();
         this.alertService.addAlert(
            this.lang().InvalidOrDeactivatedLocationAlert,
            "danger",
            6000,
         );
         return false;
      }
      return true;
   }

   private async waitLocationRequestFinished(): Promise<void> {
      const locations$ = this.manageObservables.getObservable("locationWatchVar");
      if (locations$ === undefined) {
         throw new Error("could not get locations observable");
      }
      const locationsReady$ = locations$.pipe(
         first((watchVar) => Number(watchVar) > 0),
         shareReplay(1),
      );

      await firstValueFrom(locationsReady$);
   }

   public async removeCustomLogo(): Promise<boolean> {
      const post = await this.axios({
         method: "POST",
         url: "phpscripts/manageLogin.php",
         params: {
            action: "removeCustomLogo",
         },
      });
      if (!post.data.success) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return false;
      }
      return true;
   }
}
