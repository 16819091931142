import {
   ChangeDetectionStrategy,
   Component,
   computed,
   inject,
   input,
   resource,
   signal,
   type Resource,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { IconComponent } from "@limblecmms/lim-ui";
import { TranslationService } from "src/app/languages/translation/translation.service";
import { AiIconComponent } from "src/app/tasks-analytics/predictive-maintenance/ai-icon/ai-icon.component";
import type { PmSuggestionsFilters } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/resource/pm-suggestions-resource-types";
import { PmSuggestionsResourceService } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/resource/pm-suggestions-resource.service";

@Component({
   selector: "suggestions-notification",
   templateUrl: "./suggestions-notification.component.html",
   styleUrls: ["./suggestions-notification.component.scss"],
   changeDetection: ChangeDetectionStrategy.OnPush,
   imports: [IconComponent, AiIconComponent],
})
export class SuggestionsNotificationComponent {
   public readonly limits = input<PmSuggestionsFilters>({});
   public readonly test = signal<number>(0);
   protected readonly i18n = inject(TranslationService).i18n;
   private readonly pmSuggestionsResourceService = inject(PmSuggestionsResourceService);

   public readonly count: Resource<number> = resource({
      request: () => ({ filters: this.limits(), test: this.test() }),
      loader: async ({ request }) => {
         const result = await this.pmSuggestionsResourceService.get(request);
         return result.length;
      },
   }).asReadonly();

   public readonly dismissed = signal<boolean>(false);

   public readonly isVisible = computed(
      () => (this.count.value() ?? 0) > 0 && this.dismissed() === false,
   );

   /* TODO(WRENCH-265): Hook this into the suggestions loading state.
   See https://limbleteam.slack.com/archives/C07PU42KUN5/p1743094233469029?thread_ts=1743093043.136359&cid=C07PU42KUN5 */
   public readonly animateIcon = signal<boolean>(false);

   public constructor() {
      this.pmSuggestionsResourceService.mutations
         .pipe(takeUntilDestroyed())
         .subscribe(() => {
            this.count.reload();
         });
   }

   public handleClose(clickEvent: MouseEvent) {
      clickEvent.stopPropagation();
      this.dismissed.set(true);
   }
}
