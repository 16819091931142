<div class="positive-form" [formGroup]="form">
   <lim-ui-panel>
      <div class="panel-content">
         <thank-you />
         <div class="form-group">
            <label for="comment">{{ i18n().t("ShareYourFeedback") }}</label>
            <comment-input formControlName="comment" />
         </div>
      </div>
   </lim-ui-panel>
   <div class="actions">
      <lim-ui-primary-button (click)="submit()">
         {{ i18n().t("Submit") }}
      </lim-ui-primary-button>
   </div>
</div>
