@if (isVisible() === true) {
   <div class="banner">
      <div class="content">
         <ai-icon [animate]="animateIcon()" />
         <span class="pm-suggestions-count">{{ count.value() }}</span>
         <div class="message">
            <span class="title">{{ i18n().t("AiPoweredPmBuilder") }}</span>
            <span class="separator">—</span>
            <a class="action">{{ i18n().t("ReviewNewPmSuggestions") }}</a>
         </div>
      </div>
      <div class="close" (click)="handleClose($event)">
         <lim-ui-icon icon="xmarkLargeRegular" />
      </div>
   </div>
}
