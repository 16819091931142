@if (asset) {
   <div id="assetPMPrint{{ asset?.assetID }}">
      <!-- Print related content -->
      <div class="panel" style="margin-bottom: 0px">
         <div class="panel-body printViewShow">
            <div class="col-md-12">
               <h5 style="margin: 10px 0px 10px 0px">
                  <span>
                     <lim-ui-icon
                        icon="wrench"
                        iconSize="small"
                        iconColor="orange"
                        class="icon-styling"
                     />
                     <b>{{ lang().AssetTabPlannedMaintenanceHeading }}</b> -
                     {{ lang().AssetTabPlannedMaintenanceMsg }}
                  </span>
               </h5>
            </div>
         </div>
      </div>

      <div class="pm-panels-wrapper">
         @if (pmSuggestionsFlag() === true) {
            <div
               class="pm-suggestions-notification-container"
               [style.display]="suggestionsNotification()?.isVisible() ? 'block' : 'none'"
            >
               <suggestions-notification
                  [limits]="{ locationID }"
                  (click)="suggestionsPanel()?.scrollToAndExpand()"
               />
            </div>
         }
         <!-- task builder portion of PMs -->
         <lim-ui-panel [noSidePadding]="true">
            <div class="header-section-row">
               <div class="header-items-group">
                  <span>
                     <lim-ui-icon
                        icon="file"
                        iconSize="small"
                        iconColor="orange"
                        class="icon-styling"
                     />
                     <b>{{ lang().PMTemplates }}</b>
                  </span>
                  <lim-ui-primary-button
                     icon="plus"
                     dataLogLabel="managePMs-initiateANewTemplate"
                     (click)="newTemplate()"
                     [hidden]="!(!restrict && asset?.assetDeleted == 0)"
                     >{{ lang().NewPMTemplate }}</lim-ui-primary-button
                  >
               </div>

               @if (asset.assetChildrenIDs.length > 0) {
                  <div class="checkbox-row">
                     <lim-ui-checkbox
                        [(model)]="asset.includeChildData"
                        (modelChange)="updateIncludeChildData()"
                        [disabled]="restrict || !creds?.editAssetSettings"
                        [label]="lang().IncludeChildrenData"
                     />
                     <lim-ui-icon
                        class="lim-ui-hide-on-mobile"
                        icon="circleQuestionRegular"
                        [limUiTooltip]="
                           creds?.viewManageAssets && !creds?.editAssetSettings
                              ? lang().IncludeChildrenDataCredTooltip
                              : lang().IncludeChildrenDataTooltip
                        "
                        iconSize="small"
                     />
                  </div>
               }
            </div>
            @if (templatesViewModel && !templatesViewModel.loading) {
               <div class="listTable lim-ui-responsive-table">
                  <div class="listItem listItemHeader lim-ui-responsive-table-header">
                     <sort-column
                        class="col-md-4"
                        column="checklistName"
                        name="{{ lang().Name }}"
                        [bind]="(sort$ | async)!"
                        (bindChange)="bindChanged($event)"
                     />
                     <div class="col-md-4">
                        <sort-column
                           column="recurrences"
                           name="{{ lang().Schedules }}"
                           [bind]="(sort$ | async)!"
                           (bindChange)="bindChanged($event)"
                        />
                     </div>
                     <sort-column
                        class="col-md-2"
                        column="assignment"
                        name="{{ lang().AssignedTo }}"
                        [bind]="(sort$ | async)!"
                        (bindChange)="bindChanged($event)"
                     />
                     <div class="col-md-2 listLastItem">
                        {{ lang().Options }}
                     </div>
                  </div>

                  @for (template of templatesViewModel.templates; track template) {
                     <div
                        class="listItem lim-ui-responsive-table-row"
                        [ngClass]="{ tranactionEveryOther: $even }"
                     >
                        <task-template-list-item
                           class="task-list-item"
                           [template]="template"
                           [showAssetName]="false"
                           [inAssetCard]="true"
                           [selectedAssetID]="assetID()"
                           [reachedScheduleLimit]="reachedScheduleLimit"
                        />
                     </div>
                  }
                  @if (templatesViewModel.total; as total) {
                     <div class="text-center">
                        <data-viewer-paginator
                           [areResultsPerPageEnabled]="false"
                           [totalItems]="total"
                           [page]="dataViewerState.page()"
                           [pageSize]="dataViewerState.pageSize()"
                           (pageChange)="onPageChange($event)"
                           (pageSizeChange)="onPageSizeChange($event)"
                        />
                     </div>
                  }
               </div>
            } @else {
               <lim-ui-loading-animation class="loading-spinner" />
            }
         </lim-ui-panel>
         <!-- Open PMs -->

         <lim-ui-panel [noSidePadding]="true">
            <div class="header-section-row">
               <span>
                  <lim-ui-icon
                     icon="circleExclamation"
                     iconSize="small"
                     iconColor="orange"
                     class="icon-styling"
                  />
                  <b>{{ lang().OpenPMs }}</b>
               </span>
            </div>

            <view-list-of-tasks
               [taskIDs]="filteredTasksOpenSingleIDs"
               [columns]="openPMColumns"
               [storedSortBindValue]="sortBindOpen"
               [autoHeight]="true"
               [tableInScrollablePage]="true"
            />

            <div class="no-content-message" [hidden]="!restrict">
               {{ lang().OpenPMsAreCurrentlyNotAvailable }}
            </div>

            <div
               class="no-content-message"
               [hidden]="!(filteredTasksOpenSingle.length == 0)"
            >
               <span [hidden]="!(!restrict && asset?.assetDeleted == 0)">{{
                  lang().YouDoNotHaveAnyOpenPMsForThisAsset
               }}</span>
            </div>
         </lim-ui-panel>
         <tasks-panel-legacy
            [noContentLabel]="lang().ThereAreNoCompletedPMsForThisAsset"
            [restrictedContentLabel]="lang().CompletedPMsAreCurrentlyNotAvailable"
            [columnsViewType]="'completedTasks'"
            [requestFilters]="completedTasksRequestFilters()"
            [canExport]="canExportCompletedTasks"
            [hasRestrictedPermissions]="restrict"
            [includeChildAssets]="asset.includeChildData === 1"
         >
            <span title>
               <lim-ui-icon
                  icon="circleCheck"
                  iconSize="small"
                  iconColor="orange"
                  class="icon-styling"
               />
               <b>{{ lang().CompletedPMs }}</b>
            </span>
         </tasks-panel-legacy>
         @if (pmSuggestionsFlag() === true) {
            <pm-builder-panel [limits]="{ assetID: assetID() }" />
         }
      </div>
   </div>
}
