import { ChangeDetectionStrategy, Component, inject, input, output } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { PanelComponent, PrimaryButtonComponent } from "@limblecmms/lim-ui";
import { TranslationService } from "src/app/languages/translation/translation.service";
import { CommentInputComponent } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/feedback/modal/comment-input/comment-input.component";
import { ThankYouComponent } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/feedback/modal/thank-you/thank-you.component";
import type { PmBuilderFeedback } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/feedback/pm-builder-feedback";

@Component({
   selector: "positive-form",
   templateUrl: "./positive-form.component.html",
   styleUrls: ["./positive-form.component.scss"],
   changeDetection: ChangeDetectionStrategy.OnPush,
   imports: [
      ReactiveFormsModule,
      PanelComponent,
      PrimaryButtonComponent,
      ThankYouComponent,
      CommentInputComponent,
   ],
})
export class PositiveFormComponent {
   public readonly suggestionID = input.required<number>();
   public readonly submitted = output<PmBuilderFeedback>();

   protected readonly i18n = inject(TranslationService).i18n;

   public readonly form = new FormGroup({
      comment: new FormControl<string>(""),
   });

   public submit(): void {
      this.submitted.emit({
         suggestionID: this.suggestionID(),
         isPositive: true,
         comment: this.form.value.comment ?? null,
      });
   }
}
