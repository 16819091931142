<ng-container *translate="let t">
   <lim-ui-modal>
      <lim-ui-basic-modal-header [title]="title()" (closeModal)="close()" />
      <lim-ui-modal-body>
         <lim-ui-panel>
            <div class="modal-body-content">
               <p panelHeaderContent>
                  {{ t("AreYouSureRemoveField") }}: <b>{{ fieldName() }}</b
                  >?
                  {{ t("RemoveGlobalFieldNotice") }}
               </p>
               <br />
               <p>
                  <b>{{ t("ThisActionCannotBeUndone") }}</b>
               </p>
            </div>
         </lim-ui-panel>
      </lim-ui-modal-body>
      <lim-ui-modal-footer>
         <lim-ui-secondary-button
            (click)="close()"
            data-log="assetTemplatesEditTemplateFlow-AnyStateFieldSettings-clickGoBack"
            >{{ t("GoBack") }}</lim-ui-secondary-button
         >

         <lim-ui-danger-button
            data-log="assetTemplatesEditTemplateFlow-AnyStateFieldSettings-clickFinalDeleteCustomField"
            (click)="delete()"
            >{{ t("DeleteField") }}</lim-ui-danger-button
         >
      </lim-ui-modal-footer>
   </lim-ui-modal>
</ng-container>
