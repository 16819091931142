import {
   Component,
   inject,
   output,
   type OnInit,
   input,
   computed,
   type Signal,
} from "@angular/core";
import {
   SecondaryButtonComponent,
   PrimaryButtonComponent,
   AlertComponent,
   InfoPanelComponent,
   PanelComponent,
} from "@limblecmms/lim-ui";
import { TranslationService } from "src/app/languages/translation/translation.service";
import type { Recurrence } from "src/app/schedules/recurrence.types";
import { ReoccurOptionsComponent } from "src/app/schedules/recurrences/pickReoccurModal/pickReoccur/reoccur-options.component";
import { RecurrenceComponent } from "src/app/schedules/recurrences/recurrence/recurrence.element.component";
import type { TaskTemplateEntity } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { PmBuilderFooterComponent } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-builder-wizard/footer/pm-builder-footer.component";
import type { PmSuggestion } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/entities/pm-suggestion";
import type { PmSuggestionSchedule } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/entities/pm-suggestion-schedule/pm-suggestion-schedule";

export type SuggestionsScheduleData = {
   //proto-recurrance data
   reoccurID: number | null;
   scheduleType: "metric" | "time";
   scheduleUnit: string;
   interval: number;
};
@Component({
   selector: "recurrence-step",
   templateUrl: "./recurrence-step.component.html",
   styleUrls: ["./recurrence-step.component.scss"],
   imports: [
      ReoccurOptionsComponent,
      RecurrenceComponent,
      SecondaryButtonComponent,
      PrimaryButtonComponent,
      AlertComponent,
      InfoPanelComponent,
      PanelComponent,
      PmBuilderFooterComponent,
   ],
})
export class RecurrenceStepComponent implements OnInit {
   public readonly taskTemplate = input.required<TaskTemplateEntity>();
   public readonly recurrenceID = input<number>();
   public readonly suggestion = input.required<PmSuggestion>();
   protected readonly scheduleSuggestion: Signal<PmSuggestionSchedule> = computed(() =>
      this.suggestion().getSchedule(),
   );
   protected readonly i18n = inject(TranslationService).i18n;
   public recurrence: Recurrence | undefined;
   public isJitTemplatesEnabled: boolean = false;
   public canToggleRecurrences: boolean = false;
   public readonly nextStep = output();
   public readonly prevStep = output();
   public readonly deleteRecurrenceEvent = output<number>();
   public readonly deleteTemplate = output();
   public checklistID: number | undefined;
   public suggestedFieldNotFound: boolean = false;

   public readonly setRecurrenceID = output<number>();
   private readonly manageTask = inject(ManageTask);

   public ngOnInit(): void {
      this.manageTask.fetchRecurrences();
      this.checklistID = this.taskTemplate().checklistID;
      const recurrenceID = this.recurrenceID();
      if (recurrenceID) {
         this.recurrence = this.manageTask.getRecurrence(recurrenceID);
      }
   }

   public fieldNotFound(fieldNotFound: boolean): void {
      this.suggestedFieldNotFound = fieldNotFound;
   }

   public setRecurrence(recurrenceID: number): void {
      this.recurrence = this.manageTask.getRecurrence(recurrenceID);
      this.setRecurrenceID.emit(recurrenceID);
   }

   public getScheduleSuggestionString(): string {
      if (this.scheduleSuggestion() && this.scheduleSuggestion().getType() === "metric") {
         return `${this.i18n().t("BasedOnTheAssetDocument")} ${this.scheduleSuggestion().getInterval()} ${this.scheduleSuggestion().getUnits()}`;
      }
      return "";
   }

   public deleteRecurrence(recurrenceID: number): void {
      this.deleteRecurrenceEvent.emit(recurrenceID);
   }

   public updateRecurrence(recurrence: Recurrence): void {
      this.recurrence = recurrence;
      this.setRecurrenceID.emit(recurrence.reoccurID);
   }
}
