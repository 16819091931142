export const CurrencyCodeToDetailMap = {
   "000": { name: "Non specific", symbol: "", numericCode: "000", minorUnit: 0 },
   "AED": {
      name: "United Arab Emirates dirham",
      symbol: "د.إ",
      numericCode: "784",
      minorUnit: 2,
   },
   "AFN": { name: "Afghan afghani", symbol: "؋", numericCode: "971", minorUnit: 2 },
   "ALL": { name: "Albanian lek", symbol: "L", numericCode: "008", minorUnit: 2 },
   "AMD": { name: "Armenian dram", symbol: "֏", numericCode: "051", minorUnit: 2 },
   "ANG": {
      name: "Netherlands Antillean guilder",
      symbol: "ƒ",
      numericCode: "532",
      minorUnit: 2,
   },
   "AOA": { name: "Angolan kwanza", symbol: "Kz", numericCode: "973", minorUnit: 2 },
   "AUD": { name: "Australian dollar", symbol: "$", numericCode: "036", minorUnit: 2 },
   "ARS": { name: "Argentine peso", symbol: "$", numericCode: "032", minorUnit: 2 },
   "AWG": { name: "Aruban florin", symbol: "ƒ", numericCode: "533", minorUnit: 2 },
   "AZN": { name: "Azerbaijani manat", symbol: "₼", numericCode: "944", minorUnit: 2 },
   "BAM": {
      name: "Bosnia and Herzegovina convertible mark",
      symbol: "KM",
      numericCode: "977",
      minorUnit: 2,
   },
   "BBD": { name: "Barbadian Dollar", symbol: "B$", numericCode: "052", minorUnit: 2 },
   "BDT": { name: "Bangladeshi taka", symbol: "৳", numericCode: "050", minorUnit: 2 },
   "BGN": { name: "Bulgarian lev", symbol: "лв", numericCode: "975", minorUnit: 2 },
   "BHD": { name: "Bahraini dinar", symbol: ".د.ب", numericCode: "048", minorUnit: 3 },
   "BMD": { name: "Bermudian dollar", symbol: "$", numericCode: "060", minorUnit: 2 },
   "BOB": { name: "Bolivian boliviano", symbol: "Bs.", numericCode: "068", minorUnit: 2 },
   "BRL": { name: "Brazilian real", symbol: "R$", numericCode: "986", minorUnit: 2 },
   "BSD": { name: "Bahamian dollar", symbol: "$", numericCode: "044", minorUnit: 2 },
   "BTN": { name: "Bhutanese ngultrum", symbol: "Nu.", numericCode: "064", minorUnit: 2 },
   "BWP": { name: "Botswana pula", symbol: "P", numericCode: "072", minorUnit: 2 },
   "BYN": { name: "Belarusian ruble", symbol: "Br", numericCode: "933", minorUnit: 2 },
   "BZD": { name: "Belize dollar", symbol: "$", numericCode: "084", minorUnit: 2 },
   "CAD": { name: "Canadian dollar", symbol: "$", numericCode: "124", minorUnit: 2 },
   "CHF": { name: "Swiss franc", symbol: "CHF", numericCode: "756", minorUnit: 2 },
   "CLP": { name: "Chilean peso", symbol: "$", numericCode: "152", minorUnit: 0 },
   "CNY": { name: "Chinese yuan", symbol: "¥", numericCode: "156", minorUnit: 2 },
   "COP": { name: "Colombian peso", symbol: "$", numericCode: "170", minorUnit: 2 },
   "CRC": { name: "Costa Rican colón", symbol: "₡", numericCode: "188", minorUnit: 2 },
   "CZK": { name: "Czech koruna", symbol: "Kč", numericCode: "203", minorUnit: 2 },
   "DJF": { name: "Djiboutian franc", symbol: "Fdj", numericCode: "262", minorUnit: 0 },
   "DKK": { name: "Danish krone", symbol: "kr", numericCode: "208", minorUnit: 2 },
   "DOP": { name: "Dominican peso", symbol: "$", numericCode: "214", minorUnit: 2 },
   "DZD": { name: "Algerian dinar", symbol: "د.ج", numericCode: "012", minorUnit: 2 },
   "EGP": { name: "Egyptian pound", symbol: "£", numericCode: "818", minorUnit: 2 },
   "ERN": { name: "Eritrean nakfa", symbol: "Nfk", numericCode: "232", minorUnit: 2 },
   "ETB": { name: "Ethiopian birr", symbol: "Br", numericCode: "230", minorUnit: 2 },
   "EUR": { name: "Euro", symbol: "€", numericCode: "978", minorUnit: 2 },
   "FJD": { name: "Fijian dollar", symbol: "$", numericCode: "242", minorUnit: 2 },
   "FKP": {
      name: "Falkland Islands pound",
      symbol: "£",
      numericCode: "238",
      minorUnit: 2,
   },
   "GBP": { name: "British pound", symbol: "£", numericCode: "826", minorUnit: 2 },
   "GEL": { name: "Georgian lari", symbol: "₾", numericCode: "981", minorUnit: 2 },
   "GHS": { name: "Ghanaian cedi", symbol: "₵", numericCode: "936", minorUnit: 2 },
   "GIP": { name: "Gibraltar pound", symbol: "£", numericCode: "292", minorUnit: 2 },
   "GMD": { name: "Gambian dalasi", symbol: "D", numericCode: "270", minorUnit: 2 },
   "GTQ": { name: "Guatemalan quetzal", symbol: "Q", numericCode: "320", minorUnit: 2 },
   "GYD": { name: "Guyanese dollar", symbol: "$", numericCode: "328", minorUnit: 2 },
   "HKD": { name: "Hong Kong Dollar", symbol: "HK$", numericCode: "344", minorUnit: 2 },
   "HNL": { name: "Honduran lempira", symbol: "L", numericCode: "340", minorUnit: 2 },
   "HRK": { name: "Croatian kuna", symbol: "kn", numericCode: "191", minorUnit: 2 },
   "HTG": { name: "Haitian gourde", symbol: "G", numericCode: "332", minorUnit: 2 },
   "HUF": { name: "Hungarian forint", symbol: "Ft", numericCode: "348", minorUnit: 2 },
   "IDR": { name: "Indonesian rupiah", symbol: "Rp", numericCode: "360", minorUnit: 2 },
   "ILS": { name: "Israeli new shekel", symbol: "₪", numericCode: "376", minorUnit: 2 },
   "INR": { name: "Indian rupee", symbol: "₹", numericCode: "356", minorUnit: 2 },
   "IQD": { name: "Iraqi dinar", symbol: "ع.د", numericCode: "368", minorUnit: 3 },
   "IRR": { name: "Iranian rial", symbol: "﷼", numericCode: "364", minorUnit: 2 },
   "JMD": { name: "Jamaican Dollar", symbol: "J$", numericCode: "388", minorUnit: 2 },
   "JOD": { name: "Jordanian dinar", symbol: "د.ا", numericCode: "400", minorUnit: 3 },
   "JPY": { name: "Japanese yen", symbol: "¥", numericCode: "392", minorUnit: 0 },
   "KES": { name: "Kenyan shilling", symbol: "KSh", numericCode: "404", minorUnit: 2 },
   "KMF": { name: "Comorian franc", symbol: "CF", numericCode: "174", minorUnit: 0 },
   "KPW": { name: "North Korean won", symbol: "₩", numericCode: "408", minorUnit: 2 },
   "KRW": { name: "South Korean won", symbol: "₩", numericCode: "410", minorUnit: 0 },
   "KWD": { name: "Kuwaiti dinar", symbol: "د.ك", numericCode: "414", minorUnit: 3 },
   "KZT": { name: "Kazakhstani tenge", symbol: "₸", numericCode: "398", minorUnit: 2 },
   "LAK": { name: "Lao kip", symbol: "₭", numericCode: "418", minorUnit: 2 },
   "LBP": { name: "Lebanese pound", symbol: "ل.ل", numericCode: "422", minorUnit: 2 },
   "LKR": { name: "Sri Lankan rupee", symbol: "Rs", numericCode: "144", minorUnit: 2 },
   "LSL": { name: "Lesotho loti", symbol: "L", numericCode: "426", minorUnit: 2 },
   "LYD": { name: "Libyan dinar", symbol: "ل.د", numericCode: "434", minorUnit: 3 },
   "MAD": { name: "Moroccan dirham", symbol: "د.م.", numericCode: "504", minorUnit: 2 },
   "MGA": { name: "Malagasy ariary", symbol: "Ar", numericCode: "969", minorUnit: 2 },
   "MKD": { name: "Macedonian denar", symbol: "ден", numericCode: "807", minorUnit: 2 },
   "MMK": { name: "Burmese kyat", symbol: "K", numericCode: "104", minorUnit: 2 },
   "MNT": { name: "Mongolian tögrög", symbol: "₮", numericCode: "496", minorUnit: 2 },
   "MRU": { name: "Mauritanian ouguiya", symbol: "UM", numericCode: "929", minorUnit: 2 },
   "MVR": { name: "Maldivian rufiyaa", symbol: ".ރ", numericCode: "462", minorUnit: 2 },
   "MWK": { name: "Malawian kwacha", symbol: "MK", numericCode: "454", minorUnit: 2 },
   "MXN": { name: "Mexican peso", symbol: "$", numericCode: "484", minorUnit: 2 },
   "MYR": { name: "Malaysian ringgit", symbol: "RM", numericCode: "458", minorUnit: 2 },
   "MZN": { name: "Mozambican metical", symbol: "MT", numericCode: "943", minorUnit: 2 },
   "NGN": { name: "Nigerian naira", symbol: "₦", numericCode: "566", minorUnit: 2 },
   "NIO": { name: "Nicaraguan córdoba", symbol: "C$", numericCode: "558", minorUnit: 2 },
   "NOK": { name: "Norwegian Krone", symbol: "kr", numericCode: "578", minorUnit: 2 },
   "NZD": { name: "New Zealand Dollar", symbol: "$", numericCode: "554", minorUnit: 2 },
   "OMR": { name: "Omani rial", symbol: "ر.ع.", numericCode: "512", minorUnit: 3 },
   "PEN": { name: "Peruvian sol", symbol: "S/", numericCode: "604", minorUnit: 2 },
   "PHP": { name: "Philippine piso", symbol: "₱", numericCode: "608", minorUnit: 2 },
   "PKR": { name: "Pakistani rupee", symbol: "₨", numericCode: "586", minorUnit: 2 },
   "PLN": { name: "Polish złoty", symbol: "zł", numericCode: "985", minorUnit: 2 },
   "PYG": { name: "Paraguayan guaraní", symbol: "₲", numericCode: "600", minorUnit: 0 },
   "QAR": { name: "Qatari riyal", symbol: "ر.ق", numericCode: "634", minorUnit: 2 },
   "RON": { name: "Romanian leu", symbol: "lei", numericCode: "946", minorUnit: 2 },
   "RSD": { name: "Serbian dinar", symbol: "дин.", numericCode: "941", minorUnit: 2 },
   "RUB": { name: "Russian Ruble", symbol: "₽", numericCode: "643", minorUnit: 2 },
   "SAR": { name: "Saudi riyal", symbol: "ر.س", numericCode: "682", minorUnit: 2 },
   "SDG": { name: "Sudanese pound", symbol: "ج.س.", numericCode: "938", minorUnit: 2 },
   "SEK": { name: "Swedish Krona", symbol: "kr", numericCode: "752", minorUnit: 2 },
   "SGD": { name: "Singapore dollar", symbol: "$", numericCode: "702", minorUnit: 2 },
   "SYP": { name: "Syrian pound", symbol: "£", numericCode: "760", minorUnit: 2 },
   "THB": { name: "Thai baht", symbol: "฿", numericCode: "764", minorUnit: 2 },
   "TND": { name: "Tunisian dinar", symbol: "د.ت", numericCode: "788", minorUnit: 3 },
   "TRY": { name: "Turkish lira", symbol: "₺", numericCode: "949", minorUnit: 2 },
   "TTD": {
      name: "Trinidad and Tobago Dollar",
      symbol: "TT$",
      numericCode: "780",
      minorUnit: 2,
   },
   "TZS": { name: "Tanzanian Shilling", symbol: "TZS", numericCode: "834", minorUnit: 2 },
   "USD": {
      name: "United States Dollar (usd)",
      symbol: "$",
      numericCode: "840",
      minorUnit: 2,
   },
   "VND": { name: "Vietnamese dong", symbol: "₫", numericCode: "704", minorUnit: 0 },
   "VUV": { name: "Vanuatu vatu", symbol: "VT", numericCode: "548", minorUnit: 0 },
   "XCD": {
      name: "Eastern Caribbean dollar",
      symbol: "$",
      numericCode: "951",
      minorUnit: 2,
   },
   "XOF": {
      name: "West African CFA franc",
      symbol: "CFA",
      numericCode: "952",
      minorUnit: 0,
   },
   "YER": { name: "Yemeni rial", symbol: "﷼", numericCode: "886", minorUnit: 2 },
   "ZAR": { name: "South African rand", symbol: "R", numericCode: "710", minorUnit: 2 },
   "XXX": { name: "Non specific", symbol: " ", numericCode: "999", minorUnit: 0 },
};

export interface CurrencyDetail {
   name: string;
   symbol: string;
   alphabeticCode: string;
   numericCode: string;
   minorUnit: number;
}
