import { HttpClient, HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
   map,
   type Observable,
   BehaviorSubject,
   interval,
   switchMap,
   takeWhile,
   type Subscription,
   tap,
   of,
   exhaustMap,
} from "rxjs";
import { ApplyTemplateService } from "src/app/assets/services/apply-asset-templates/apply-asset-template.service";
import type {
   ApplyTemplateCriteria,
   FieldCollisions,
} from "src/app/assets/services/apply-asset-templates/apply-asset-templates.models";
import {
   AssetTemplateApiService,
   type AssetTemplate,
   type AssetTemplateStatus,
} from "src/app/assets/services/asset-template-api.service";
import { TemplatesPageStore } from "src/app/assets/services/templatesPage.store";
import { environment } from "src/environments/environment";

@Injectable({
   providedIn: "root",
})
export class AssetTemplateService {
   private readonly http = inject(HttpClient);
   private readonly applyTemplateService = inject(ApplyTemplateService);
   private readonly router = inject(Router);
   private readonly store = inject(TemplatesPageStore);
   private readonly assetTemplateApiService = inject(AssetTemplateApiService);
   private readonly templatesPageStore = inject(TemplatesPageStore);

   private readonly baseUrl: string = `${environment.servicesURL()}/assets`;
   private readonly assetTemplateURL: string = "template";

   public readonly deletionStatusSubject = new BehaviorSubject<string>("");
   public readonly deletionStatus$ = this.deletionStatusSubject.asObservable();

   public readonly jobProgressSubject = new BehaviorSubject<number>(0);
   public readonly jobProgress$ = this.jobProgressSubject.asObservable();

   private readonly pollingSubscription: Map<number, Subscription> = new Map();

   // TODO this needs to be updated to support multiple templates with outstanding jobs and support editing status
   public startPolling(templateId: number, action: "delete" | "apply"): void {
      // Clean up any existing subscription
      this.stopPolling(templateId);

      this.handlePolling(templateId, action);
   }

   private handlePolling(templateId: number, action: "delete" | "apply") {
      this.pollingSubscription.set(
         templateId,
         interval(1000)
            .pipe(
               exhaustMap(() => this.checkTemplateStatus(templateId)), // Using exhaustMap to ensure that the request is not interrupted by the interval
               switchMap((templateStatus) => {
                  const isProcessing =
                     action === "delete"
                        ? templateStatus.Status === "deleting"
                        : templateStatus.Status === "applying";

                  if (action === "delete") {
                     this.deletionStatusSubject.next(templateStatus.Status);
                  } else {
                     this.applyTemplateService.setApplyingMode(templateStatus);
                  }
                  this.jobProgressSubject.next(templateStatus.JobProgress ?? 0);

                  return of(isProcessing);
               }),
               takeWhile((shouldContinue) => shouldContinue, true),
            )
            .subscribe({
               complete: () => {
                  // Refetch the template in case we weren't able to get it the first time
                  // This will only be needed until we remove the backwards compatibility from the backend
                  // When it was checking the status with the get template endpoints
                  this.templatesPageStore.fetchTemplate();

                  this.templatesPageStore.fetchAssets();
                  this.deletionStatusSubject.next("");
                  this.applyTemplateService.clearApplyingMode();
                  this.jobProgressSubject.next(0);
               },
            }),
      );
   }

   public stopPolling(templateId: number): void {
      this.pollingSubscription.get(templateId)?.unsubscribe();
      this.pollingSubscription.delete(templateId);
   }

   /**
    * Gets a single asset template by ID.
    * @param templateID - The ID of the template to retrieve.
    * @returns An Observable of the asset template.
    */
   public getTemplate(templateID: number): Observable<AssetTemplate> {
      return this.assetTemplateApiService.getById(templateID).pipe(
         map((data) => {
            if (Array.isArray(data)) {
               return data[0];
            }
            return data;
         }),
      );
   }

   public checkTemplateStatus(templateID: number): Observable<AssetTemplate> {
      return this.assetTemplateApiService.checkStatus(templateID).pipe(
         map((data) => {
            if (Array.isArray(data)) {
               return data[0];
            }
            return data;
         }),
      );
   }

   public checkAllTemplateStatuses(): Observable<AssetTemplateStatus[]> {
      return this.assetTemplateApiService.checkAllStatuses();
   }

   /**
    * Gets a list of all asset templates.
    * @returns An Observable of the list of asset templates.
    */
   public getTemplates(statuses: string[] = []): Observable<AssetTemplate[]> {
      const params =
         statuses.length > 0
            ? new HttpParams().set("status", statuses.join(","))
            : new HttpParams();
      return this.http.get<AssetTemplate[]>(`${this.baseUrl}/${this.assetTemplateURL}/`, {
         withCredentials: true,
         params,
      });
   }

   /**
    * Creates a new asset template.
    * @param body - The request body containing the name of the new template. If empty, default values are used.
    * @returns An Observable of the created asset template.
    */
   public createTemplate(body: { Name?: string } = {}): Observable<AssetTemplate> {
      // NOTE currently the Primary key is the name this needs to change in Lumberyard
      return this.http.post<AssetTemplate>(
         `${this.baseUrl}/${this.assetTemplateURL}/`,
         body,
         { withCredentials: true },
      );
   }

   /**
    * Used to find assets with field merge conflicts for template fields that will merge into local fields.
    * An asset has a field collision if it has two or more fields that a single template field is set
    * to merge into.
    */
   public checkMergeFieldCollisions(
      applyTemplateCriteria: ApplyTemplateCriteria,
   ): Observable<FieldCollisions> {
      return this.http.post<FieldCollisions>(
         `${this.baseUrl}/template-field/check-collisions`,
         applyTemplateCriteria,
         { withCredentials: true },
      );
   }

   public applyTemplate(
      templateID: number,
      request: ApplyTemplateCriteria,
   ): Observable<AssetTemplate> {
      return this.http
         .post<{
            message: string;
            success: boolean;
            template: AssetTemplate;
         }>(`${this.baseUrl}/template/${templateID}/apply`, request, {
            withCredentials: true,
         })
         .pipe(
            map((response) => response?.template),
            tap((template) => {
               this.applyTemplateService.setApplyingMode(template);
               this.jobProgressSubject.next(template.JobProgress ?? 0);
               this.startPolling(template.ID, "apply");
               this.router.navigate(["/assetTemplates", template.ID]);
            }),
         );
   }

   /**
    * Updates an existing asset template's name or draft status.
    * @param templateID - The ID of the template to update.
    * @param body - The request body containing the updated name and draft status.
    * @returns An Observable of the updated asset template or an error message.
    */
   public updateTemplate(
      templateID: number,
      body: { name: string; status: string },
   ): Observable<AssetTemplate> {
      return this.http
         .patch<AssetTemplate>(
            `${this.baseUrl}/${this.assetTemplateURL}/${templateID}`,
            body,
            { withCredentials: true },
         )
         .pipe(
            tap(() => {
               this.store.fetchTemplate();
            }),
         );
   }

   /**
    * Soft deletes an asset template by ID.
    * @param templateID - The ID of the template to delete.
    * @returns An Observable of void.
    */
   public deleteTemplate(templateID: number): Observable<AssetTemplate> {
      return this.http.delete<AssetTemplate>(
         `${this.baseUrl}/${this.assetTemplateURL}/${templateID}`,
         { withCredentials: true },
      );
   }
}
