<lim-ui-modal [small]="true">
   <lim-ui-basic-modal-header
      [title]="i18n().t('PmSuggestionsFeedback')"
      (closeModal)="close()"
   />
   <lim-ui-modal-body>
      @if (submitted() === true) {
         <feedback-submitted (close)="close()" />
      } @else {
         @if (isPositive() === true) {
            <positive-form
               [suggestionID]="suggestionID()"
               (submitted)="handleFeedback($event)"
            />
         } @else {
            <negative-form
               [suggestionID]="suggestionID()"
               (submitted)="handleFeedback($event)"
            />
         }
      }
   </lim-ui-modal-body>
</lim-ui-modal>
