<lim-ui-scroll-container>
   <task-form [data]="taskInput()" class="chk-wrapper" />
</lim-ui-scroll-container>
<pm-builder-footer [suggestionID]="suggestionID()">
   <div class="navigation">
      <lim-ui-secondary-button (click)="prevStep.emit()" [fullWidthOnMobile]="true">
         {{ i18n().t("Back") + ": " + i18n().t("AssignedTo") }}
      </lim-ui-secondary-button>
      <lim-ui-primary-button (click)="done.emit()" [fullWidthOnMobile]="true">
         {{ i18n().t("Done") }}
      </lim-ui-primary-button>
   </div>
</pm-builder-footer>
