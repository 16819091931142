<lim-ui-scroll-container>
   <div class="step-body">
      <pick-user-or-profile
         [message]="i18n().t('ChangeTheTasksAssignmentMsg')"
         [locationID]="locationID()"
         [extraUsers]="extraUsersOptions"
         [defaultUser]="userOrProfileData()['selectedUsers']?.[0]?.userID"
         [defaultProfile]="userOrProfileData()['selectedProfile']?.profileID"
         (selection)="setUserOrProfileSelection($event)"
      />
   </div>
</lim-ui-scroll-container>
<pm-builder-footer [suggestionID]="suggestionID()">
   <div class="navigation">
      <lim-ui-secondary-button (click)="prevStep.emit()" [fullWidthOnMobile]="true">
         {{ i18n().t("Back") + ": " + i18n().t("Schedules") }}
      </lim-ui-secondary-button>
      <lim-ui-primary-button
         (click)="saveAndContinue()"
         [fullWidthOnMobile]="true"
         [disabled]="noSelectionMade()"
      >
         {{ i18n().t("Next") + ": " + i18n().t("Preview") }}
      </lim-ui-primary-button>
   </div>
</pm-builder-footer>
