import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { TranslationService } from "src/app/languages/translation/translation.service";

@Component({
   selector: "thank-you",
   templateUrl: "./thank-you.component.html",
   styleUrls: ["./thank-you.component.scss"],
   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThankYouComponent {
   protected readonly i18n = inject(TranslationService).i18n;
}
