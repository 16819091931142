import { NgClass } from "@angular/common";
import {
   Component,
   forwardRef,
   inject,
   Input,
   input,
   type OnDestroy,
   type OnInit,
   ViewChild,
   computed,
   ViewContainerRef,
   type AfterViewInit,
   signal,
   DestroyRef,
   type Signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormsModule } from "@angular/forms";
import {
   DragEndEvent,
   TreeBranch,
   type TreeOptions,
   type TreeRoot,
   TreeService,
} from "@limble/limble-tree";
import {
   IconComponent,
   LimbleHtmlDirective,
   ModalService,
   PanelComponent,
   PrimaryButtonComponent,
   TooltipDirective,
   LoadingBarService,
   AlertComponent,
} from "@limblecmms/lim-ui";
import { injectQuery } from "@tanstack/angular-query-experimental";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import {
   filter,
   startWith,
   type Subscription,
   switchMap,
   merge,
   skip,
   ReplaySubject,
   combineLatest,
} from "rxjs";
import { take } from "rxjs/operators";
import { AddAssetField } from "src/app/assets/components/addAssetFieldModal/addAssetField.modal.component";
import { AssetInformationItem } from "src/app/assets/components/assetInformationItemElement/assetInformationItem.element.component";
import { AssetParentList } from "src/app/assets/components/assetParentList/assetParentList.element.component";
import { EditAssetField } from "src/app/assets/components/editAssetFieldModal/editAssetField.modal.component";
import { UpdateAssetHoursOfOperation } from "src/app/assets/components/updateAssetHoursOfOperationModal/updateAssetHoursOfOperation.modal.component";
import { AssetFieldTypeID } from "src/app/assets/schemata/fields/types/asset-field-type.enum";
import { AssetFieldValuesService } from "src/app/assets/services/asset-field-values.service";
import {
   AssetFieldScopeType,
   type AssetTemplateField,
} from "src/app/assets/services/asset-field.types";
import { AssetTemplateFieldService } from "src/app/assets/services/asset-template-field.service";
import { AssetTemplateService } from "src/app/assets/services/asset-template.service";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import type { AssetField } from "src/app/assets/types/field/asset-field.types";
import type { AssetFieldValue } from "src/app/assets/types/field/value/value.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { LocationQueriesService } from "src/app/locations/services/queries/location-queries.service";
import { ViewMap } from "src/app/maps/components/viewMapModal/viewMap.modal.component";
import { ManageAtlas } from "src/app/maps/services/manageAtlas";
import { ManageMaps } from "src/app/maps/services/manageMaps";
import { MultiCurrencyAvailabilityService } from "src/app/purchasing/currency/services/availability/multi-currency-availability.service";
import { CurrencyDisplayService } from "src/app/purchasing/currency/services/display/currency-display.service";
import { ManagePO } from "src/app/purchasing/services/managePO";
import type { GeneralLedger } from "src/app/purchasing/types/general-ledger.types";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { LaunchFlagsService } from "src/app/shared/services/launch-flags/launch-flags.service";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import {
   ManageObservables,
   type ObservableNames,
} from "src/app/shared/services/manageObservables";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ManageWebhooks } from "src/app/shared/services/manageWebhooks";
import { ParamsService } from "src/app/shared/services/params.service";
import type { FeatureFlag } from "src/app/shared/types/general.types";
import { assert } from "src/app/shared/utils/assert.utils";
import type { Lookup } from "src/app/shared/utils/lookup";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";
import { AccountSettingsQueriesService } from "src/app/users/services/queries/account-settings-queries.service";

type AssetCreds = {
   changeAssetName: boolean;
   changeAssetFieldsValues: boolean;
   configureAssetFields: boolean;
};

@Component({
   selector: "asset-information-tab",
   templateUrl: "./asset-information-tab.component.html",
   styleUrls: ["./asset-information-tab.component.scss"],
   imports: [
      PanelComponent,
      IconComponent,
      FormsModule,
      NgClass,
      ContenteditableDirective,
      TooltipDirective,
      forwardRef(() => AssetParentList),
      LimbleHtmlDirective,
      PrimaryButtonComponent,
      NgxSkeletonLoaderModule,
      AlertComponent,
   ],
})
export class AssetInformationTabComponent implements OnInit, AfterViewInit, OnDestroy {
   @ViewChild("limbleTreeContainer", { read: ViewContainerRef })
   public treeContainer?: ViewContainerRef;
   public asset = input.required<Asset>();
   @Input() public preventParentAccess: boolean | undefined;
   @Input() public restrict: boolean = false;

   public encodedArcGisAPICred: string | undefined;
   public locationMappedToArcGis: boolean = false;
   public currentFeatureServiceID: number = 0;
   public featureArcGIS: FeatureFlag = 0;
   public fieldValues: Array<AssetFieldValue> = [];
   public oldAssetName: string | null = null;
   public assetFieldsSub: Subscription | null = null;
   public assetFieldsObs: ObservableNames | null = null;
   public taskAsset: Asset | undefined;
   public ledgers: Array<GeneralLedger> = [];
   public ledger: string = "";
   public hasGL: boolean = false;
   public featureMaps: FeatureFlag = 0;
   private readonly flagAssetFieldValuesJIT: Signal<boolean>;

   public configAssetMapCred: boolean = false;
   public assetFieldValues: Lookup<"valueID", AssetFieldValue>;
   public creds: AssetCreds = {
      changeAssetName: false,
      changeAssetFieldsValues: false,
      configureAssetFields: false,
   };

   protected assetTemplateName: string = "";
   protected templateFieldsMap: Map<number, AssetTemplateField> = new Map();

   private treeOptions: TreeOptions = {};
   private tree?: TreeRoot<AssetInformationItem>;

   private allAssetFieldsSub: Subscription | undefined;
   private readonly viewInitialized$ = new ReplaySubject<void>(1);

   private readonly currentUserSub: Subscription;

   private readonly alertService = inject(AlertService);
   private readonly manageAsset = inject(ManageAsset);
   private readonly loadingBarService = inject(LoadingBarService);
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageFilters = inject(ManageFilters);
   private readonly managePO = inject(ManagePO);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly manageMaps = inject(ManageMaps);
   private readonly manageAtlas = inject(ManageAtlas);
   private readonly manageWebhooks = inject(ManageWebhooks);
   private readonly manageUser = inject(ManageUser);
   private readonly credService = inject(CredService);
   private readonly manageLang = inject(ManageLang);
   private readonly assetTemplateService = inject(AssetTemplateService);
   private readonly assetTemplateFieldService = inject(AssetTemplateFieldService);
   private readonly treeService = inject(TreeService);
   private readonly manageUtil = inject(ManageUtil);
   private readonly destroyRef = inject(DestroyRef);
   private readonly currencyDisplayService = inject(CurrencyDisplayService);
   private readonly launchFlagsService = inject(LaunchFlagsService);
   private readonly accountSettingsQueries = inject(AccountSettingsQueriesService);
   private readonly isMultiCurrencyEnabled = inject(MultiCurrencyAvailabilityService)
      .isEnabled;
   private readonly locationQueries = inject(LocationQueriesService);
   private readonly assetFieldValuesService = inject(AssetFieldValuesService);

   private readonly locationID = signal<number | undefined>(undefined);
   private readonly accountCurrencyQuery = injectQuery(() =>
      this.accountSettingsQueries.currencyDetail(),
   );
   private readonly locationQuery = injectQuery(() =>
      this.locationQueries.detail(this.locationID()),
   );
   protected readonly currencyCode = this.currencyDisplayService.evaluateSignal(
      computed(() => this.accountCurrencyQuery.data()?.currencyCode),
      computed(() => this.locationQuery.data()?.currencyCode ?? []),
      this.isMultiCurrencyEnabled,
   );

   private treeEventsSubscription;

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   protected isLoading = signal<boolean>(true);
   protected isErrorLoadingfieldsValues = signal<boolean>(false);

   protected skeletonThemes = this.manageUtil.generateSkeletonLoaderThemes();

   protected get isGeoLocationListItem(): boolean {
      return (
         (this.featureMaps !== 0 &&
            Boolean(this.asset().geoLocation ?? this.configAssetMapCred)) ||
         this.hasGL
      );
   }

   public constructor() {
      this.assetFieldValues = this.manageAsset.getFieldValues();

      this.currentUserSub = this.manageUser.currentUserChanges$.subscribe(() => {
         this.configAssetMapCred = this.credService.checkCredAnywhere(
            this.credService.Permissions.ConfigureAssetMaps,
         );
         this.featureMaps = this.manageUser.getCurrentUser().userInfo.featureMaps;
         this.featureArcGIS = this.manageUser.getCurrentUser().userInfo.featureArcGIS;
      });

      this.setTreeOptions();

      this.flagAssetFieldValuesJIT = this.launchFlagsService.getFlag(
         "release-asset-field-values-jit",
         false,
      );
   }

   public ngOnInit(): void {
      assert(
         this.asset() !== undefined,
         "assetInformation component requires an `asset` input binding, but a value was not provided.",
      );

      if (this.flagAssetFieldValuesJIT()) {
         this.allAssetFieldsSub = combineLatest([
            this.manageAsset.fieldState(),
            this.assetFieldValuesService.getFieldValues(this.asset().assetID, {
               updateState: true,
            }),
            this.manageAsset.tileFieldsObs$.pipe(startWith(null)),
         ]).subscribe({
            next: ([fieldState, fieldValues]) => {
               if (fieldState) {
                  this.assetFieldValuesService
                     .getFieldValues(this.asset().assetID, {
                        updateState: true,
                     })
                     .pipe(takeUntilDestroyed(this.destroyRef))
                     .subscribe((updatedFieldValues) => {
                        this.fieldValues = updatedFieldValues;
                        this.transformFields();
                        this.getAssetTemplateData();
                     });
               } else {
                  this.fieldValues = fieldValues;
                  this.transformFields();
                  this.getAssetTemplateData();
               }
            },
            error: () => {
               this.isLoading.set(false);
               this.isErrorLoadingfieldsValues.set(true);
            },
         });
      } else {
         this.allAssetFieldsSub = merge(
            this.manageAsset.fieldState(),
            this.manageAsset.fieldValueState(),
         )
            .pipe(skip(1), takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
               this.buildFields();
            });

         this.getAssetTemplateData();

         this.assetFieldsObs = `assetFields${this.asset().assetID}`;
         this.assetFieldsSub = this.manageObservables.createAndSubscribe(
            this.assetFieldsObs,
            this.buildFields.bind(this),
         );
      }

      this.getAssetCreds();

      this.locationID.set(this.asset().locationID);
      this.ledgers = this.managePO.getGeneralLedgersForAsset(
         this.asset().assetID,
         this.asset().locationID,
      );
      if (this.ledgers.length) {
         this.hasGL = true;
      }
      this.ledger = this.ledgers
         .map((ledger, index) => {
            return `${this.ledgers[index].abbr}`;
         })
         .join(", ");

      this.oldAssetName = this.asset().assetName;

      if (this.featureArcGIS !== 1) return;
      this.manageWebhooks
         .getEncodedAPICredByType("arcgis")
         .then((encodedArcGisAPICred) => {
            if (
               !encodedArcGisAPICred ||
               encodedArcGisAPICred.data.encodedSecret === null
            ) {
               return;
            }
            this.encodedArcGisAPICred = encodedArcGisAPICred.data.encodedSecret;
            this.manageAtlas
               .getCurrentAtlasUser(this.encodedArcGisAPICred)
               .then((atlasCustomer) => {
                  if (
                     atlasCustomer.data.customerActive !== 1 ||
                     atlasCustomer.data.gisUsername === null
                  ) {
                     return;
                  }
                  this.manageAtlas
                     .getFeatureServices(this.encodedArcGisAPICred)
                     .then((featureService) => {
                        if (!featureService.data.featureID) {
                           return;
                        }
                        this.currentFeatureServiceID = featureService.data.featureID;
                        this.manageAtlas
                           .getAssetMap(
                              this.encodedArcGisAPICred,
                              this.asset().assetID,
                              featureService.data.featureID,
                           )
                           .then((assetMap) => {
                              if (assetMap.data) {
                                 this.locationMappedToArcGis = true;
                              }
                           });
                     });
               });
         });
   }

   public ngAfterViewInit(): void {
      this.viewInitialized$
         .pipe(
            switchMap(() => this.manageAsset.tileFieldsObs$.pipe(startWith(null))),
            takeUntilDestroyed(this.destroyRef),
         )
         .subscribe({
            next: () => {
               this.isLoading.set(false);
               if (this.treeContainer === undefined) {
                  throw new Error("Could not get tree container");
               }
               this.tree = this.treeService.createEmptyTree<AssetInformationItem>(
                  this.treeContainer,
                  this.treeOptions,
               );

               for (const fieldValue of this.fieldValues) {
                  const fieldInfo = this.manageAsset.getField(fieldValue.fieldID);
                  if (!fieldInfo) {
                     continue;
                  }
                  this.tree.grow(AssetInformationItem, {
                     inputBindings: {
                        restrict: this.restrict,
                        assetTemplateName: this.assetTemplateName,
                        templateFieldsMap: this.templateFieldsMap,
                        currencyCode: this.currencyCode,
                     },
                     meta: {
                        nodeData: {
                           fieldValue,
                           fieldInfo,
                           isDraggable: this.canDragField(fieldInfo),
                        },
                     },
                  });
               }

               this.treeEventsSubscription = this.tree
                  .events()
                  .pipe(
                     filter(
                        (event): event is DragEndEvent<AssetInformationItem> =>
                           event instanceof DragEndEvent,
                     ),
                  )
                  .subscribe((event) => {
                     this.onMoveNode(event);
                  });
            },
         });
   }

   public ngOnDestroy() {
      this.manageObservables.unsubscribeAndDelete(
         this.assetFieldsObs ?? `assetFields${Number(this.asset().assetID)}`,
         this.assetFieldsSub,
      );
      this.allAssetFieldsSub?.unsubscribe();
      this.currentUserSub.unsubscribe();
      this.treeEventsSubscription?.unsubscribe();
      this.tree?.destroy();
   }

   /** function called when you drag/drop an item */
   protected async onMoveNode(event: DragEndEvent<AssetInformationItem>): Promise<void> {
      const updates: Array<[number, number]> = [];
      const draggedField = this.fieldValues.splice(event.oldIndex(), 1);
      this.fieldValues.splice(event.newIndex(), 0, draggedField[0]);
      for (const [index, field] of this.fieldValues.entries()) {
         field.valueSort = index + 1;
         updates.push([field.valueID, field.valueSort]);
      }

      const answer = await this.manageAsset.updateSorts(updates, this.asset().locationID);
      if (answer.data.success === true) {
         this.manageAsset.tileFieldsObs$.next(null);
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
      }
   }

   protected updateAssetName() {
      if (this.oldAssetName === this.asset().assetName) {
         return;
      }

      /* When a name input is empty, it returns "<br>" rather than an empty string.
       * Simply checking this.asset().assetName === "" won't catch this case.
       * We strip HTML tags to ensure we're checking the actual text content.
       */
      const strippedName = this.manageUtil.stripTags(this.asset().assetName ?? "");

      if (strippedName === "") {
         this.asset().assetName = this.oldAssetName;
         this.alertService.addAlert(this.lang().NameFieldCannotBeBlank, "danger", 10000);
         return;
      }

      const shouldAllowNumericalAssetNames = this.launchFlagsService.getFlag(
         "release-numerical-asset-names",
         false,
      );
      if (
         shouldAllowNumericalAssetNames() === false &&
         this.manageAsset.isNumCheck(strippedName)
      ) {
         this.asset().assetName = this.oldAssetName;
         this.alertService.addAlert(
            this.lang().AssetNamesRequireAtLeastOneNonnumericCharacter,
            "danger",
            10000,
         );
         return;
      }

      if (
         !this.credService.isAuthorized(
            this.asset().locationID,
            this.credService.Permissions.ChangeAssetName,
         )
      ) {
         this.alertService.addAlert(this.lang().cred63Fail, "danger", 10000);
         return;
      }
      this.asset().assetName = this.manageFilters.htmlToPlaintext(this.asset().assetName);

      this.manageAsset.updateAssetName(this.asset()).then((answer) => {
         if (answer.data.success === true) {
            this.oldAssetName = this.asset().assetName;

            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else if (answer.data.error === "nonUniqueValue") {
            this.asset().assetName = this.oldAssetName;
            this.alertService.addAlert(
               this.lang().DefaultFieldUniqueError,
               "warning",
               6000,
            );
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });
   }

   protected async addField() {
      if (
         !this.credService.isAuthorized(
            this.asset().locationID,
            this.credService.Permissions.ConfigureAssetInformationFields,
         )
      ) {
         this.alertService.addAlert(this.lang().Cred64Fail, "danger", 10000);
         return;
      }

      const instance = this.modalService.open(AddAssetField);
      instance.componentInstance.assetID.set(this.asset().assetID);

      const result = await instance.result;
      if (result === 0) {
         return;
      }

      if (result.view === "suggested") {
         await this.addMultipleExistingFields(this.asset(), result);
      }

      if (result.view === "new") {
         await this.addNewField(this.asset(), result);
      }

      if (result.view === "updated") {
         this.manageAsset.tileFieldsObs$.next(null);
      }
   }

   private isFieldInTemplate(fieldID: number): boolean {
      const templateID = this.asset().templateID;
      if (!templateID) {
         return false;
      }

      return this.templateFieldsMap.has(fieldID);
   }

   private canDragField(fieldInfo: AssetField | undefined): boolean {
      if (!fieldInfo) {
         return false;
      }

      /**
       * Can drag any field that isn't standardized.
       */
      if (fieldInfo?.scopeType !== AssetFieldScopeType.Standardized) {
         return true;
      }

      /**
       * Can drag standardized fields if they are not in the template
       * that the asset is currently linked to.
       */
      return !this.isFieldInTemplate(fieldInfo.fieldID);
   }

   private canDropField(sourceBranch, treeRoot, proposedIndex): boolean {
      const siblingBranch = treeRoot.getBranch(proposedIndex);
      const siblingData =
         siblingBranch instanceof TreeBranch ? siblingBranch.meta().nodeData : null;
      const fieldInfo = siblingData?.fieldInfo;

      /**
       * No field info means they're trying to place it last
       * in the list.
       *
       * Otherwise the logic is the same as the drag check.
       */
      return !fieldInfo || this.canDragField(fieldInfo);
   }

   private setTreeOptions() {
      this.treeOptions = {
         indentation: 16,
         dragAndDrop: {
            allowDragging: (treeBranch) => {
               const fieldInfo = treeBranch.meta().nodeData?.fieldInfo;
               return this.canDragField(fieldInfo);
            },
            allowDrop: (sourceBranch, treeRoot, proposedIndex) => {
               return this.canDropField(sourceBranch, treeRoot, proposedIndex);
            },
            allowNesting: () => false,
         },
      };
   }

   private async addMultipleExistingFields(asset, result): Promise<void> {
      for await (const field of result.field) {
         await this.addExistingField({ asset, field });
      }
   }

   private async addExistingField(obj): Promise<void> {
      const answer = await this.manageAsset.addExistingField(obj.asset, obj.field);
      if (answer?.data.success === true) {
         //update the parent and the child's tile fields
         this.manageAsset.tileFieldsObs$.next(null);
         this.alertService.addAlert(this.lang().FieldSuccessfullyAdded, "success", 1000);
      } else {
         this.alertService.addAlert(
            answer?.data?.msg ?? this.lang().errorMsg,
            "danger",
            6000,
         );
      }
   }

   private async addNewField(asset, result): Promise<void> {
      const answer = await this.manageAsset.addNewField(
         asset,
         result.type,
         result.name,
         result.options,
      );

      if (answer?.data.success === true) {
         this.manageAsset.tileFieldsObs$.next(null);
         this.alertService.addAlert(this.lang().FieldSuccessfullyAdded, "success", 1000);
         //if it is a dropdown open up the dialog to edit the fellow
         if (answer.data.row.fieldTypeID === AssetFieldTypeID.Dropdown) {
            this.addDropdown(answer.data.row);
         }
      } else {
         if (answer?.data?.reason === "duplicatedName") {
            this.alertService.addAlert(
               this.lang().cantNotNameFieldTheSameAsAnotherField,
               "warning",
               6000,
            );
            return;
         }
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
      }
   }

   addDropdown = (field) => {
      const instance = this.modalService.open(EditAssetField);
      instance.componentInstance.fieldID.set(field.fieldID);

      instance.result.then((result) => {
         if (result.delete) {
            this.loadingBarService.show({ header: this.lang()?.WakingUpHamsters });
            this.manageAsset.removeSuggestedField(field).then((answer) => {
               this.loadingBarService.remove();
               if (answer?.data.success === true) {
                  for (const [idx, fieldToRemove] of this.fieldValues.entries()) {
                     if (fieldToRemove.fieldID === field.fieldID) {
                        this.fieldValues.splice(idx, 1);
                     }
                  }
                  this.alertService.addAlert(this.lang().successMsg, "success", 1000);
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               }
            });
         }
         //update fields view and options
         this.manageAsset.tileFieldsObs$.next(null);
      });
   };

   updateHoursOfOperation = (asset: Asset) => {
      const instance = this.modalService.open(UpdateAssetHoursOfOperation);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().UpdateHoursOfOperationPerWeekTitleMsg,
            title: `${this.lang().UpdateHoursOfOperationPerWeekTitle} - ${asset.assetName}`,
            asset: asset,
         },
      };
   };

   protected async openViewMap() {
      const instance = this.modalService.open(ViewMap);
      const canEdit = this.credService.checkCredAnywhere(
         this.credService.Permissions.ConfigureAssetMaps,
      );
      const title = this.asset().geoLocation
         ? this.lang().ViewMapLocation
         : this.lang().SetMapLocation;
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            asset: this.asset(),
            locationID: this.asset().locationID,
            title: title,
            canEdit: canEdit,
            locationMappedToArcGis: this.locationMappedToArcGis,
            currentFeatureServiceID: this.currentFeatureServiceID,
            encodedArcGisAPICred: this.encodedArcGisAPICred,
         },
      };
      const result = await instance.result;
      if (result?.locationMappedToArcGis) {
         this.locationMappedToArcGis = result.locationMappedToArcGis;
      }
   }

   protected navigateToLocation(geoLocation) {
      this.manageMaps.navigateToGeolocation(geoLocation.geometry.coordinates);
   }

   private getAssetCreds() {
      this.creds.changeAssetName = this.credService.isAuthorized(
         this.asset().locationID,
         this.credService.Permissions.ChangeAssetName,
      );

      this.creds.configureAssetFields = this.credService.isAuthorized(
         this.asset().locationID,
         this.credService.Permissions.ConfigureAssetInformationFields,
      );

      this.creds.changeAssetFieldsValues = this.credService.isAuthorized(
         this.asset().locationID,
         this.credService.Permissions.ChangeAssetInformationValues,
      );
   }

   private buildFields() {
      this.fieldValues = [];

      for (const valueID of this.asset().assetValueIDs) {
         const value = this.assetFieldValues.get(valueID);
         if (!value) {
            continue;
         }
         this.fieldValues.push(value);
      }

      this.transformFields();
   }

   private transformFields() {
      this.fieldValues = orderBy(this.fieldValues, "valueSort");

      // Doing this as to filter out soft-deleted values if they weren't already filtered out in the backend.
      this.fieldValues = this.fieldValues.filter((value) => !value.valueDeleted);

      if (
         !this.credService.isAuthorized(
            this.asset().locationID,
            this.credService.Permissions.ViewManageAssets,
         )
      ) {
         this.fieldValues = this.fieldValues.filter(
            (field) => field.viewableByTech === 1,
         );
      }
   }

   private getAssetTemplateData() {
      const templateID = this.asset().templateID;
      if (templateID) {
         this.assetTemplateService
            .getTemplate(templateID)
            .pipe(take(1))
            .subscribe({
               next: (template) => {
                  if (
                     template &&
                     (template.Status === "published" ||
                        template.Status === "editing" ||
                        template.Status === "applying")
                  ) {
                     this.assetTemplateName = template.Name;
                     this.assetTemplateFieldService
                        .getTemplateFieldsMap(templateID)
                        .pipe(take(1))
                        .subscribe({
                           next: (fieldsMap) => {
                              this.templateFieldsMap = fieldsMap;
                              this.viewInitialized$.next();
                           },
                           error: () => {
                              this.alertService.addAlert(
                                 this.lang().errorMsg,
                                 "danger",
                                 1000,
                              );
                           },
                        });
                  } else {
                     this.viewInitialized$.next();
                  }
               },
               error: () => {
                  this.viewInitialized$.next();
               },
            });
      } else {
         this.viewInitialized$.next();
      }
   }
}
