import { Component, inject, signal } from "@angular/core";
import {
   BasicModalHeaderComponent,
   DangerButtonComponent,
   LimUiModalRef,
   ModalBodyComponent,
   ModalComponent,
   ModalFooterComponent,
   type ModalResult,
   PanelComponent,
   SecondaryButtonComponent,
} from "@limblecmms/lim-ui";
import { TranslateDirective } from "src/app/languages/i18n/translate.directive";

@Component({
   selector: "confirm-global-field-delete",
   imports: [
      BasicModalHeaderComponent,
      ModalComponent,
      ModalBodyComponent,
      PanelComponent,
      ModalFooterComponent,
      DangerButtonComponent,
      SecondaryButtonComponent,
      TranslateDirective,
   ],
   templateUrl: "./confirm-global-field-delete.component.html",
})
export class ConfirmGlobalFieldDeleteComponent
   implements ModalResult<boolean | undefined>
{
   public readonly modalRef: LimUiModalRef<
      ConfirmGlobalFieldDeleteComponent,
      boolean | undefined
   > = inject(LimUiModalRef);

   public title = signal<string>("");
   public fieldName = signal<string>("");

   protected delete() {
      this.modalRef.close(true);
   }

   protected close() {
      this.modalRef.close(false);
   }
}
