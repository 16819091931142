import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import type { PmBuilderFeedback } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/feedback/pm-builder-feedback";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class PmBuilderFeedbackApiService {
   private readonly http = inject(HttpClient);

   private readonly baseUrl = `${environment.servicesURL()}/schedules`;
   private readonly baseOptions = { withCredentials: true };

   public async create(feedback: PmBuilderFeedback): Promise<void> {
      await firstValueFrom(
         this.http.post(
            `${this.baseUrl}/pm-builder-feedback`,
            feedback,
            this.baseOptions,
         ),
      );
   }
}
