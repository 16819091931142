<div class="step-body">
   <div class="info-panel-container">
      <lim-ui-info-panel class="info-panel">
         {{ i18n().t("PleaseEditHowOftenYouWouldLikeThisTask") }}
      </lim-ui-info-panel>

      @if (recurrence) {
         <lim-ui-panel>
            <recurrence
               [taskTemplate]="taskTemplate()"
               [isJitTemplatesEnabled]="true"
               [recurrence]="recurrence"
               [canToggleRecurrences]="canToggleRecurrences"
               (recurrenceDeleted)="deleteRecurrence($event)"
               (recurrenceUpdated)="updateRecurrence($event)"
               [useModalPopup]="false"
            />
         </lim-ui-panel>
      } @else {
         @if (scheduleSuggestion() && scheduleSuggestion().getType() === "metric") {
            <div class="alert-wrapper">
               <lim-ui-alert alertType="info" displayType="banner">
                  <span>{{ getScheduleSuggestionString() }}</span>
               </lim-ui-alert>
            </div>
         }
         @if (
            scheduleSuggestion() &&
            scheduleSuggestion()?.getType() === "metric" &&
            suggestedFieldNotFound
         ) {
            <div class="alert-wrapper">
               <lim-ui-alert alertType="warning" displayType="banner">
                  <span
                     >{{ i18n().t("WeWereUnableToFieldMatch") }}
                     <b>{{ scheduleSuggestion()?.getUnits() }}</b></span
                  >
                  <span>{{ i18n().t("YouCaanSelectAnExistingAssetField") }}</span>
               </lim-ui-alert>
            </div>
         }
         <lim-ui-panel>
            <reoccur-options
               [taskTemplate]="taskTemplate()"
               [scheduleSuggestion]="scheduleSuggestion()"
               (fieldNotFound)="fieldNotFound($event)"
               (recurrenceID)="setRecurrence($event)"
            />
         </lim-ui-panel>
      }
   </div>
</div>
<pm-builder-footer [suggestionID]="suggestion().getId()">
   <span class="navigation">
      <lim-ui-secondary-button (click)="prevStep.emit()" [fullWidthOnMobile]="true">
         {{ i18n().t("Back") }}: {{ i18n().t("Schedules") }}
      </lim-ui-secondary-button>
      <lim-ui-primary-button (click)="nextStep.emit()" [fullWidthOnMobile]="true">
         {{ i18n().t("Next") + ": " + i18n().t("AssignedTo") }}
      </lim-ui-primary-button>
   </span>
</pm-builder-footer>
