import { ChangeDetectionStrategy, Component, inject, input, output } from "@angular/core";
import { DangerButtonComponent, OutlinedButtonComponent } from "@limblecmms/lim-ui";
import { TranslationService } from "src/app/languages/translation/translation.service";
import { FeedbackSolicitationComponent } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/feedback/solicitation/feedback-solicitation.component";

@Component({
   selector: "pm-builder-footer",
   templateUrl: "./pm-builder-footer.component.html",
   styleUrls: ["./pm-builder-footer.component.scss"],
   changeDetection: ChangeDetectionStrategy.OnPush,
   imports: [
      OutlinedButtonComponent,
      DangerButtonComponent,
      FeedbackSolicitationComponent,
   ],
})
export class PmBuilderFooterComponent {
   public readonly suggestionID = input.required<number>();
   public readonly deleteTemplate = output();
   protected readonly i18n = inject(TranslationService).i18n;
}
