import {
   inject,
   Component,
   EventEmitter,
   Input,
   Output,
   computed,
   input,
} from "@angular/core";
import {
   LimbleHtmlDirective,
   PrimaryButtonComponent,
   SecondaryButtonComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";

@Component({
   selector: "empty-here",
   templateUrl: "./emptyHere.element.component.html",
   styleUrls: ["./emptyHere.element.component.scss"],
   imports: [PrimaryButtonComponent, SecondaryButtonComponent, LimbleHtmlDirective],
})
export class EmptyHere {
   @Input() public addText: string = "";
   @Input() public showAddButton: boolean = true;
   @Input() public addButtonDisabled: boolean = false;
   @Input() public dataLogAddButtonLabel: string | undefined;
   @Input() public customText: string = "";
   @Input() public importText: string = "";
   public readonly addButtonSecondary = input<boolean>(false);
   public readonly showDefaultSubtitle = input<boolean>(true);
   @Output() public readonly emptyHereAddButtonClicked = new EventEmitter();
   @Output() public readonly importButtonClicked = new EventEmitter();

   public readonly customTitle = input<string | undefined>(undefined);

   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public addTextBtnClicked() {
      this.emptyHereAddButtonClicked.emit();
   }

   protected triggerImport() {
      this.importButtonClicked.emit();
   }
}
