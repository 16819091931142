import { Component, input } from "@angular/core";
import type { RequestInformation } from "src/app/tasks/components/shared/services/work-request-submissions-api/work-request-submissions-api.models";
import { CustomWorkRequestFieldComponent } from "src/app/tasks/components/workRequests/work-request-submission-modal/work-request-custom-field/custom-work-request-field.component";

@Component({
   selector: "work-request-information",
   imports: [CustomWorkRequestFieldComponent],
   templateUrl: "./work-request-information.component.html",
   styleUrl: "./../shared/work-request-submission-styles.scss",
})
export class WorkRequestInformationComponent {
   requestInformation = input<Partial<RequestInformation>>();
}
