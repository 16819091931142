import { computed, inject, Injectable } from "@angular/core";
import { LoadingBarService } from "@limblecmms/lim-ui";
import { lastValueFrom } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { AlertService } from "src/app/shared/services/alert.service";
import { BetterDate } from "src/app/shared/services/betterDate";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import type {
   ListResponse,
   RequestOptions,
} from "src/app/shared/services/flannel-api-service";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { SchedulesApiService } from "src/app/tasks/components/shared/services/schedules-api/schedules-api.service";
import {
   TaskTemplatesApiService,
   type PmTemplatesExportData,
} from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.service";
import type {
   TaskEntity,
   TaskEntityFilters,
} from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import { TasksApiService } from "src/app/tasks/components/shared/services/tasks-api/tasks-api.service";
import { TasksSchedulesCombinedApiService } from "src/app/tasks/components/shared/services/tasks-schedules-combined-api/tasks-schedules-combined-api.service";
import type { TasksSchedulesCombinedEntity } from "src/app/tasks/components/shared/services/tasks-schedules-combined-api/tasks-schedules-combined.models";
import { DefaultStatus } from "src/app/tasks/schemata/statuses/status.enum";
import { FakePercentageTimerService } from "src/app/tasks/services/fake-percentage-timer/fake-percentage-timer.service";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { TaskCalendarDataService } from "src/app/tasks-analytics/calendar/task-calendar/task-calendar-data.service";
import { CredService } from "src/app/users/services/creds/cred.service";

/**
 * This is a temporary service that will go away when the backend has
 * an endpoint to download instead of doing this all in the UI.
 */
@Injectable({ providedIn: "root" })
export class TaskExporterService {
   private readonly tasksAPIService = inject(TasksApiService);
   private readonly tscApiService = inject(TasksSchedulesCombinedApiService);
   private readonly manageTask = inject(ManageTask);
   private readonly betterDate = inject(BetterDate);
   private readonly manageUtil = inject(ManageUtil);
   private readonly loadingBarService = inject(LoadingBarService);
   private readonly fakePercentageTimerService = inject(FakePercentageTimerService);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly alertService = inject(AlertService);
   private readonly credService = inject(CredService);
   private readonly taskCalendarDataService = inject(TaskCalendarDataService);
   private readonly schedulesApiService = inject(SchedulesApiService);
   private readonly taskTemplatesApiService = inject(TaskTemplatesApiService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public async exportVisibleData(
      requestOptions: Partial<RequestOptions<TaskEntityFilters>> &
         Pick<RequestOptions<TaskEntityFilters>, "pagination">,
      columns: { [key: string]: boolean | undefined },
      isTaskAndSchedules?: boolean,
   ) {
      const api = isTaskAndSchedules ? this.tscApiService : this.tasksAPIService;

      this.loadingBarService.show({
         header: this.lang().ThisMayTakeAMoment,
         msg: this.lang().ExportingTasksToExcel,
         showProgressBar: true,
      });
      this.fakePercentageTimerService.setLoadingTimer(
         requestOptions.pagination?.limit ?? 1,
         2000,
      );

      const listResponse = await lastValueFrom<
         Partial<ListResponse<TasksSchedulesCombinedEntity | TaskEntity>>
      >(
         api.getList({
            ...requestOptions,
            columns: requestOptions.columns
               ? `${requestOptions.columns},comments,invoices,extraTime`
               : "comments,invoices,extraTime",
         }),
      );

      const data = listResponse.data;

      if (data === undefined) {
         this.fakePercentageTimerService.clearLoadingTimer();
         this.loadingBarService.remove();
         return;
      }

      if (isTaskAndSchedules) {
         const dataMappedToExcelFormat = data
            .filter(
               (
                  taskScheduleCombinedEntity,
               ): taskScheduleCombinedEntity is TasksSchedulesCombinedEntity =>
                  taskScheduleCombinedEntity !== undefined,
            )
            .map((entity) =>
               this.manageTask.mapTaskScheduleCombinedEntityToExcelFormat(entity),
            );
         this.convertToExcel(dataMappedToExcelFormat);
      } else {
         const tasks = data.filter(
            (taskEntity): taskEntity is TaskEntity => taskEntity !== undefined,
         );
         this.manageTask.downloadTasksLimited(tasks, columns);
      }

      this.fakePercentageTimerService.clearLoadingTimer();
      this.loadingBarService.remove();
   }

   public async exportEverything(
      requestOptions: Partial<RequestOptions<TaskEntityFilters>>,
      isTaskAndSchedules?: boolean,
   ): Promise<void> {
      const api = isTaskAndSchedules ? this.tscApiService : this.tasksAPIService;

      this.loadingBarService.show({
         header: this.lang().ThisMayTakeAMoment,
         msg: this.lang().ExportingTasksToExcel,
         showProgressBar: true,
      });

      const total = await api.getTotal(requestOptions);

      this.fakePercentageTimerService.setLoadingTimer(total, 2000);

      const data = await api.getAllItems(
         {
            ...requestOptions,
            columns: requestOptions.columns
               ? `${requestOptions.columns},comments,invoices,extraTime`
               : "comments,invoices,extraTime",
         },
         total,
      );

      if (isTaskAndSchedules) {
         const dataMappedToExcelFormat = data
            .filter(
               (
                  taskScheduleCombinedEntity,
               ): taskScheduleCombinedEntity is TasksSchedulesCombinedEntity =>
                  taskScheduleCombinedEntity !== undefined,
            )
            .map((entity) =>
               this.manageTask.mapTaskScheduleCombinedEntityToExcelFormat(entity),
            );
         this.convertToExcel(dataMappedToExcelFormat);
      } else {
         const tasks = data.filter(
            (taskEntity): taskEntity is TaskEntity => taskEntity !== undefined,
         );
         await this.manageTask.downloadExcel(tasks);
      }

      this.fakePercentageTimerService.clearLoadingTimer();
      this.loadingBarService.remove();
   }

   public async exportOpenAndUpcomingTasks(locationID: number, search: string) {
      const featurePMImportExport = this.manageFeatureFlags.hasFeatureExportPMs();
      if (!featurePMImportExport) {
         return;
      }

      if (
         this.alertService.noCredAtLocationAlert(
            locationID,
            this.credService.Permissions.ExportPMs,
         )
      ) {
         return;
      }

      const tasks = this.taskCalendarDataService.getTasks({
         search: search,
         locationIDs: [locationID],
         statusesNot: [DefaultStatus.Completed],
      });

      const schedules = await this.schedulesApiService.getAll({
         filters: { search, locationIDs: [locationID] },
      });

      this.manageTask.downloadExcelSchedules(schedules, tasks);
   }

   public async exportTemplatesAtLocation(
      locationID: number,
      search: string,
   ): Promise<void> {
      const templates = await this.taskTemplatesApiService.getAllItems({
         filters: { locationIDs: [locationID], search, excludeDeletedAssets: true },
      });

      if (templates.length === 0) {
         this.alertService.addAlert(
            this.lang().ThereIsNothingToDownload,
            "warning",
            6000,
         );
         return;
      }

      const templateIDs: number[] = templates.map((template) => template.checklistID);
      const chunkSize = 500;
      const templateIDChunks: number[][] = [...this.chunks(templateIDs, chunkSize)];
      try {
         let combinedObjs: PmTemplatesExportData[] = [];
         for await (const data of this.getStreamedExportPmTemplates(templateIDChunks)) {
            combinedObjs = [...combinedObjs, ...data];
         }

         const today = this.betterDate.createTodayTimestamp();
         const fileName = `PM Templates-${today}.xlsx`;
         this.manageUtil.objToExcel(combinedObjs, "PM Templates", fileName);
      } catch (error: unknown) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         if (error instanceof Error) {
            throw error;
         }
         console.error(error);
         throw new Error("Failed to export PM Templates");
      }
   }

   private convertToExcel(tasks: Array<any>) {
      const today = this.betterDate.createTodayTimestamp();
      const fileName = `${this.lang().Tasks}-${today}.xlsx`;
      this.manageUtil.objToExcel(tasks, "Tasks", fileName);
   }

   private *chunks(arr, chunkSize) {
      for (let i = 0; i < arr.length; i += chunkSize) {
         yield arr.slice(i, i + chunkSize);
      }
   }

   private async *getStreamedExportPmTemplates(
      templateIDChunks: number[][],
   ): AsyncGenerator<PmTemplatesExportData[], void> {
      let currentChunkIDs: number[];
      let chunkIndex = 0;

      do {
         currentChunkIDs = templateIDChunks[chunkIndex];
         const response =
            // eslint-disable-next-line no-await-in-loop -- This is a generator, Promise.all doesn't make sense.
            await this.taskTemplatesApiService.exportPmTemplates(currentChunkIDs);
         if (response.data === "") {
            throw new Error("Export Failed");
         }
         yield response.data;
         chunkIndex++;
      } while (chunkIndex < templateIDChunks.length);
   }
}
