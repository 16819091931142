<div class="feedback">
   <div>Are these results helpful?</div>
   <lim-ui-outlined-button
      [isIconButton]="true"
      (click)="openPositiveFeedbackForm()"
      data-log="pm-builder-feedback-positive"
   >
      <lim-ui-icon icon="thumbsUpRegular" />
   </lim-ui-outlined-button>
   <lim-ui-outlined-button
      [isIconButton]="true"
      (click)="openNegativeFeedbackForm()"
      data-log="pm-builder-feedback-negative"
   >
      <lim-ui-icon icon="thumbsDownRegular" />
   </lim-ui-outlined-button>
</div>
