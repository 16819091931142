@let submissionInformation = requestInformation();

@if (submissionInformation) {
   @if (submissionInformation.customFieldOne) {
      <custom-work-request-field
         [title]="submissionInformation.customFieldOneTitle ?? ''"
         [value]="submissionInformation.customFieldOne ?? ''"
         data-test-id="custom-field-one"
      />
   }
   @if (submissionInformation.customFieldTwo) {
      <custom-work-request-field
         [title]="submissionInformation.customFieldTwoTitle ?? ''"
         [value]="submissionInformation.customFieldTwo ?? ''"
         data-test-id="custom-field-two"
      />
   }
   @if (submissionInformation.customFieldThree) {
      <custom-work-request-field
         [title]="submissionInformation.customFieldThreeTitle ?? ''"
         [value]="submissionInformation.customFieldThree ?? ''"
         data-test-id="custom-field-three"
      />
   }
   @if (submissionInformation.customFieldDropdownAnswerOne) {
      <custom-work-request-field
         [title]="submissionInformation.customDropdownTitleOne ?? ''"
         [value]="submissionInformation.customFieldDropdownAnswerOne ?? ''"
         data-test-id="custom-dropdown-one"
      />
   }
   @if (submissionInformation.customFieldDropdownAnswerTwo) {
      <custom-work-request-field
         [title]="submissionInformation.customDropdownTitleTwo ?? ''"
         [value]="submissionInformation.customFieldDropdownAnswerTwo ?? ''"
         data-test-id="custom-dropdown-two"
      />
   }
   @if (submissionInformation.customFieldDropdownAnswerThree) {
      <custom-work-request-field
         [title]="submissionInformation.customDropdownTitleThree ?? ''"
         [value]="submissionInformation.customFieldDropdownAnswerThree ?? ''"
         data-test-id="custom-dropdown-three"
      />
   }
}
