import { ChangeDetectionStrategy, Component, inject, input } from "@angular/core";
import { IconComponent, ModalService, OutlinedButtonComponent } from "@limblecmms/lim-ui";
import { FeedbackModalComponent } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/feedback/modal/feedback-modal.component";

@Component({
   selector: "feedback-solicitation",
   templateUrl: "./feedback-solicitation.component.html",
   styleUrls: ["./feedback-solicitation.component.scss"],
   changeDetection: ChangeDetectionStrategy.OnPush,
   imports: [OutlinedButtonComponent, IconComponent],
})
export class FeedbackSolicitationComponent {
   public readonly suggestionID = input.required<number>();

   private readonly modalService = inject(ModalService);

   public async openPositiveFeedbackForm(): Promise<void> {
      const modal = this.modalService.open(FeedbackModalComponent);
      modal.setInput("suggestionID", this.suggestionID());
      modal.setInput("isPositive", true);
      await modal.result;
   }

   public async openNegativeFeedbackForm(): Promise<void> {
      const modal = this.modalService.open(FeedbackModalComponent);
      modal.setInput("suggestionID", this.suggestionID());
      modal.setInput("isPositive", false);
      await modal.result;
   }
}
