<div class="negative-form" [formGroup]="form">
   <lim-ui-panel>
      <div class="panel-content">
         <thank-you />
         <div class="form-group">
            <label for="reason">{{ i18n().t("WhichOptionBestDescribesTheIssue") }}</label>
            @for (reason of reasons; track reason) {
               <div class="radio-item">
                  <input type="radio" [value]="reason.id" formControlName="reason" />
                  {{ reason.display }}
               </div>
            }
         </div>
         <div class="form-group">
            <label for="comment">{{ i18n().t("ShareMoreDetails") }}</label>
            <comment-input formControlName="comment" />
         </div>
      </div>
   </lim-ui-panel>
   <div class="actions">
      <lim-ui-primary-button (click)="submit()">
         {{ i18n().t("Submit") }}
      </lim-ui-primary-button>
   </div>
</div>
