import { ChangeDetectionStrategy, Component, forwardRef } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import type { ControlValueAccessor } from "@angular/forms";
import { FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { distinctUntilChanged } from "rxjs";

@Component({
   selector: "comment-input",
   templateUrl: "./comment-input.component.html",
   styleUrls: ["./comment-input.component.scss"],
   changeDetection: ChangeDetectionStrategy.OnPush,
   imports: [ReactiveFormsModule],
   providers: [
      {
         provide: NG_VALUE_ACCESSOR,
         multi: true,
         useExisting: forwardRef(() => CommentInputComponent),
      },
   ],
})
export class CommentInputComponent implements ControlValueAccessor {
   public readonly comment = new FormControl<string>("");

   public constructor() {
      this.comment.valueChanges
         .pipe(takeUntilDestroyed(), distinctUntilChanged())
         .subscribe((comment) => {
            this.onChange(comment);
         });
   }

   private onChange: (comment: string | null) => void = () => {
      /* Do nothing. This will be overridden by the parent component via
      `registerOnChange`. */
   };
   protected onTouched: () => void = () => {
      /* Do nothing. This will be overridden by the parent component via
      `registerOnTouched`. */
   };

   public writeValue(value: string): void {
      this.comment.setValue(value);
   }

   public registerOnChange(fn: (comment: string | null) => void): void {
      this.onChange = fn;
   }

   public registerOnTouched(fn: () => void): void {
      this.onTouched = fn;
   }

   public setDisabledState(isDisabled: boolean): void {
      if (isDisabled) {
         this.comment.disable();
      } else {
         this.comment.enable();
      }
   }
}
