<lim-ui-scroll-container>
   <div class="step-body">
      <div class="alert-wrapper">
         <lim-ui-alert alertType="success" displayType="banner">
            <span>{{ i18n().t("PMTemplateCreatedReview") }}</span>
         </lim-ui-alert>
      </div>
      <task-form [data]="taskInput()" class="chk-wrapper" />
   </div>
</lim-ui-scroll-container>
<pm-builder-footer [suggestionID]="suggestionID()">
   <div class="navigation">
      <lim-ui-primary-button (click)="nextStep.emit()" [fullWidthOnMobile]="true">
         {{ i18n().t("Next") + ": " + i18n().t("Schedules") }}
      </lim-ui-primary-button>
   </div>
</pm-builder-footer>
