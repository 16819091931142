<div class="step-footer">
   <div class="options">
      <lim-ui-danger-button
         (click)="deleteTemplate.emit()"
         classNames="mobile-full-width"
         class="delete-template-mobile"
      >
         {{ i18n().t("DeleteTemplate") }}
      </lim-ui-danger-button>
      <lim-ui-outlined-button
         color="red"
         (click)="deleteTemplate.emit()"
         class="delete-template-desktop"
      >
         {{ i18n().t("DeleteTemplate") }}
      </lim-ui-outlined-button>
      <feedback-solicitation [suggestionID]="suggestionID()" />
   </div>
   <ng-content />
</div>
