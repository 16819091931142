import { ChangeDetectionStrategy, Component, inject, input, output } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { PanelComponent, PrimaryButtonComponent } from "@limblecmms/lim-ui";
import { TranslationService } from "src/app/languages/translation/translation.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { CommentInputComponent } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/feedback/modal/comment-input/comment-input.component";
import { ThankYouComponent } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/feedback/modal/thank-you/thank-you.component";
import type { PmBuilderFeedback } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/feedback/pm-builder-feedback";

@Component({
   selector: "negative-form",
   templateUrl: "./negative-form.component.html",
   styleUrls: ["./negative-form.component.scss"],
   changeDetection: ChangeDetectionStrategy.OnPush,
   imports: [
      ReactiveFormsModule,
      PanelComponent,
      PrimaryButtonComponent,
      ThankYouComponent,
      CommentInputComponent,
   ],
})
export class NegativeFormComponent {
   public readonly suggestionID = input.required<number>();
   public readonly submitted = output<PmBuilderFeedback>();

   protected readonly i18n = inject(TranslationService).i18n;
   protected readonly reasons = [
      { id: "Unhelpful", display: this.i18n().t("Unhelpful") },
      { id: "Incorrect", display: this.i18n().t("NotFactuallyAccurate") },
      { id: "Poor design", display: this.i18n().t("ProductDesignOrFunctionality") },
      { id: "Other", display: this.i18n().t("Other") },
   ];

   public readonly form = new FormGroup({
      comment: new FormControl<string>(""),
      reason: new FormControl<string>("", {
         validators: [Validators.required.bind(this)],
      }),
   });

   public submit(): void {
      if (this.form.invalid) {
         return;
      }
      const reason = this.form.value.reason;
      assert(
         reason,
         "It is not possible for the reason to be empty after form validation.",
      );
      this.submitted.emit({
         suggestionID: this.suggestionID(),
         isPositive: false,
         comment: this.form.value.comment ?? null,
         reason: reason,
      });
   }
}
