import { NgClass } from "@angular/common";
import type { OnInit, WritableSignal } from "@angular/core";
import { inject, Component, computed, signal } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   BasicModalFooterComponent,
   BasicModalHeaderComponent,
   DropdownDividerComponent,
   DropdownTextItemComponent,
   FormDropdownInputComponent,
   InfoPanelComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   RadioButtonComponent,
   SearchBoxComponent,
   AlertComponent,
   TextButtonComponent,
   IconComponent,
   LoadingAnimationComponent,
} from "@limblecmms/lim-ui";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { take } from "rxjs";
import AssetHierarchyBuilder from "src/app/assets/components/pickNewAssetTypeModal/assetHierarchyBuilder";
import type { AssetTemplate } from "src/app/assets/services/asset-template-api.service";
import {
   AssetTemplateSettingsService,
   type AssetTemplateAccountSettings,
} from "src/app/assets/services/asset-template-settings.service";
import { AssetTemplateService } from "src/app/assets/services/asset-template.service";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import type { CreateAssetSelection } from "src/app/assets/types/create-asset-selection.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { LocationHierarchyService } from "src/app/shared/components/global/global-nav/location-hierarchy/location-hierarchy.service";
import { HierarchyContainerLegacy } from "src/app/shared/components/global/hierarchy-legacy/hierarchy-container-legacy-component/hierarchy-container-legacy.component";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { AutoFocusDirective } from "src/app/shared/directives/autofocus/autoFocus.directive";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { BetterDate } from "src/app/shared/services/betterDate";
import { FeatureFlagService } from "src/app/shared/services/feature-flags/feature-flag.service";
import { Flags, LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags";
import { LaunchFlagsService } from "src/app/shared/services/launch-flags/launch-flags.service";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import type { HierarchyNode, HierarchyOptions } from "src/app/shared/types/general.types";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import type { Lookup } from "src/app/shared/utils/lookup";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

type AssetHierarchyNode = HierarchyNode & {
   uniqueID?: string;
   assetID: number;
   templateID: number | undefined;
};

@Component({
   selector: "pick-new-asset-type",
   templateUrl: "./pickNewAssetType.modal.component.html",
   styleUrls: ["./pickNewAssetType.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      FormsModule,
      AutoFocusDirective,
      FormDropdownInputComponent,
      SearchBoxComponent,
      DropdownDividerComponent,
      DropdownTextItemComponent,
      NgClass,
      PanelComponent,
      RadioButtonComponent,
      NoSearchResults,
      HierarchyContainerLegacy,
      BasicModalFooterComponent,
      AlertComponent,
      TextButtonComponent,
      IconComponent,
      LoadingAnimationComponent,
      NgxSkeletonLoaderModule,
   ],
})
export class PickNewAssetType implements OnInit {
   public modalInstance;
   public message;
   public title;
   public errorMsg;
   public selection: CreateAssetSelection;
   public show;
   public allLocations;
   public loadCount;
   public loadedHier;
   public dontChangeName;
   public tmpLocations;
   public locations;
   public assets: Lookup<"assetID", Asset>;
   public assetNodes: LimbleMap<number, AssetHierarchyNode> = new LimbleMap();
   public treeData;
   public assetsSpeedy;
   public name: string = "";
   public locationID;
   public selectedLocationName;
   public setLocation;
   public totalLocLength;
   public searchLocations;
   public searchBar;
   public searchBarBefore;
   public noSearchResults;
   public assetsLength;
   public searchTimer;
   public timeout;
   public currentLocationID: number = 0;
   public hierarchyOptions: HierarchyOptions;
   public showSuccessAssetTemplatesAlert = false;
   public showInfoAssetTemplatesAlert = true;
   public templatesStatus: WritableSignal<AssetTemplateAccountSettings | undefined> =
      signal(undefined);
   public assetTemplates: WritableSignal<AssetTemplate[] | undefined> = signal(undefined);
   public selectedTemplate: AssetTemplate | undefined;

   private readonly manageLocation = inject(ManageLocation);
   private readonly manageAsset = inject(ManageAsset);
   private readonly credService = inject(CredService);
   private readonly alertService = inject(AlertService);
   private readonly manageFilters = inject(ManageFilters);
   private readonly paramsService = inject(ParamsService);
   private readonly betterDate = inject(BetterDate);
   private readonly manageUser = inject(ManageUser);
   private readonly locationHierarchyService = inject(LocationHierarchyService);
   private readonly manageLang = inject(ManageLang);
   private readonly legacyLaunchFlagsService = inject(LegacyLaunchFlagsService);
   private readonly launchFlagsService = inject(LaunchFlagsService);
   private readonly manageUtil = inject(ManageUtil);
   private readonly assetTemplateSettingsService = inject(AssetTemplateSettingsService);
   private readonly assetTemplateService = inject(AssetTemplateService);
   private assetHierarchyBuilder!: AssetHierarchyBuilder;
   private readonly featureFlagService = inject(FeatureFlagService);

   protected skeletonThemes = this.manageUtil.generateSkeletonLoaderThemes();

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   protected readonly assetTemplatesLaunchFlag: WritableSignal<boolean | undefined> =
      signal(undefined);
   protected isAssetTemplatesEnabled = computed(() => {
      return (
         this.assetTemplatesLaunchFlag() &&
         this.featureFlagService.featureSet()?.has("assetTemplates")
      );
   });

   public newAssetOptionsLoading = computed(
      () =>
         this.isAssetTemplatesEnabled() === undefined ||
         (this.isAssetTemplatesEnabled() === true &&
            this.templatesStatus() === undefined),
   );

   public constructor() {
      //we actually see all assets from before so this works out.
      this.assets = this.manageAsset.getAssets();
      this.hierarchyOptions = {
         idKey: "assetID",
         selection: {
            singleSelection: true,
         },
         nodeButtons: [
            {
               tooltip: this.lang().OpenThisAsset,
               clickFunction: this.runPopAsset.bind(this),
               permissionNumber: this.credService.Permissions.ViewLookupAnAsset,
               text: this.lang().View,
            },
         ],
         onSelect: this.setName.bind(this),
         submit: this.submit.bind(this),
      };
      this.selection = "blank";
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.currentLocationID = params.resolve?.currentLocationID ?? 0;
         this.name = params.resolve?.newAssetSearchTerm ?? "";
      }

      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }
      this.message = this.lang().AddAnAssetMsg;
      this.title = this.lang().AddAnAsset;
      this.errorMsg = false;
      this.loadCount = 0;
      this.loadedHier = false;
      this.dontChangeName = false;

      this.legacyLaunchFlagsService.isEnabled(Flags.ASSET_TEMPLATES).then((enabled) => {
         this.assetTemplatesLaunchFlag.set(enabled);

         if (this.isAssetTemplatesEnabled()) {
            this.assetTemplateSettingsService
               .getAccountSettings()
               .pipe(take(1))
               .subscribe((templatesStatus) => {
                  this.templatesStatus.set(templatesStatus);

                  if (templatesStatus.templatesStatus === "required") {
                     this.selection = "template";
                     this.getAssetTemplates();
                  }
               });

            if (sessionStorage.getItem("showSuccessAssetTemplatesAlert") === null) {
               const isSuperUser = this.manageUtil.checkIfSuperUser(
                  this.manageUser.getCurrentUser(),
               );
               this.showSuccessAssetTemplatesAlert =
                  Boolean(this.isAssetTemplatesEnabled()) && isSuperUser;
            } else {
               this.showSuccessAssetTemplatesAlert = Boolean(
                  sessionStorage.getItem("showSuccessAssetTemplatesAlert"),
               );
            }
         }
      });

      //needed for just their locations if they need to pick which location this asset should be in (currentLocationID)
      this.tmpLocations = this.manageLocation.getLocations();

      this.buildLocData();

      this.manageLocation.getAllLocations().then((answer) => {
         //needed for for copying from other locations
         this.allLocations = answer.data.locations || [];
      });

      //this reduces the size of the assets array so the watch can work faster... at least in theory
      this.assetsSpeedy = this.manageFilters.filterAssetsForLookupSpeed(this.assets);

      if (this.currentLocationID === 0) {
         this.locationID = this.locations[0].locationID;
         this.selectedLocationName = this.locations[0].locationName;
      } else {
         this.locationID = this.currentLocationID;
      }

      this.setLocation = (location) => {
         this.locationID = location.locationID;
         this.selectedLocationName = location.locationName;
      };
   }

   public dismissSuccessAssetTemplatesAlert() {
      this.showSuccessAssetTemplatesAlert = false;
      sessionStorage.setItem("showSuccessAssetTemplatesAlert", "");
   }

   public dismissInfoAssetTemplatesAlert() {
      this.showInfoAssetTemplatesAlert = false;
   }

   updateSearch = () => {
      if (this.searchBarBefore != this.searchBar) {
         this.searchBarBefore = this.searchBar;
         if (this.searchTimer) {
            clearTimeout(this.searchTimer);
         }

         this.searchTimer = setTimeout(() => {
            this.buildCopyAssetList();
         }, 250);
      }
   };

   protected runLocationSearch(locationSearch: string): void {
      this.searchLocations = locationSearch;
      this.buildLocData();
   }

   protected buildLocData(): void {
      this.locations = [];
      let buildLocations = this.manageLocation.getLocations();
      this.totalLocLength = buildLocations.length;

      if (this.searchLocations && this.searchLocations.length > 0) {
         buildLocations = this.manageFilters.filterLocationsToSearch(
            buildLocations,
            this.searchLocations,
         );
      }

      buildLocations = orderBy(buildLocations, "locationNameWithRegions");

      this.locations = buildLocations;
   }

   public async buildCopyAssetList(initialBuild?: boolean): Promise<void> {
      if (!this.allLocations) {
         const result = await this.manageLocation.getAllLocations();
         this.allLocations = result.data.locations ?? [];
      }

      this.assetHierarchyBuilder = new AssetHierarchyBuilder(
         this.manageLocation,
         this.manageAsset,
         this.credService,
         this.manageFilters,
         this.locationHierarchyService,
         this.betterDate,
         this.manageUser,
         this.templatesStatus,
         this.assets,
         this.allLocations,
         this.searchBar,
         this.assetNodes,
         this.treeData,
         this.noSearchResults,
      );
      const result = this.assetHierarchyBuilder.buildList(initialBuild);
      this.treeData = result.treeData;
      this.noSearchResults = result.noSearchResults;
      this.assetsLength = this.assets?.size ?? 0;
   }

   public getAssetTemplates() {
      this.assetTemplates.set(undefined);
      this.assetTemplateService
         .getTemplates(["published", "editing", "applying"])
         .pipe(take(1))
         .subscribe((assetTemplates) => {
            this.assetTemplates.set(assetTemplates);
            if (assetTemplates.length === 1) {
               this.selectedTemplate = assetTemplates[0];
            }
         });
   }

   setDontChangeName = () => {
      if (this.name != "") {
         this.dontChangeName = true;
      }
   };

   public setName() {
      if (this.dontChangeName) {
         return;
      }
      const selectedNode = this.assetNodes.find((node) => node.selected === true);
      if (!selectedNode) {
         return;
      }
      this.name = `${selectedNode.title} - ${this.lang().Copy}`;
   }

   close = () => {
      this.modalInstance.close(0);
   };

   private notValidNameCheck(): boolean {
      if (this.name.length < 1) {
         this.errorMsg = this.lang().PleaseEnterAValidName;
         this.alertService.addAlert(this.errorMsg, "warning", 6000);

         return true;
      }
      const shouldAllowNumericalAssetNames = this.launchFlagsService.getFlag(
         "release-numerical-asset-names",
         false,
      );
      if (
         this.manageAsset.isNumCheck(this.name) &&
         shouldAllowNumericalAssetNames() === false
      ) {
         this.errorMsg = this.lang().AssetNamesRequireAtLeastOneNonnumericCharacter;
         this.alertService.addAlert(this.errorMsg, "warning", 6000);

         return true;
      }
      return false;
   }

   public async submit() {
      this.errorMsg = false;

      if (this.selection == null) {
         this.errorMsg = this.lang().PleaseSelectAValidOption;
         this.alertService.addAlert(this.errorMsg, "warning", 6000);
         return;
      }

      if (this.selection) {
         if (this.notValidNameCheck()) {
            return;
         }
      }

      if (this.selection === "template") {
         if (this.selectedTemplate === undefined) {
            this.errorMsg = this.lang().PleaseSelectAValidOption;
            this.alertService.addAlert(this.errorMsg, "warning", 6000);
            return;
         }
      }

      let selectedAssetID;
      if (this.selection === "copy") {
         let found = false;
         for (const [index, asset] of this.assetNodes.entries()) {
            if (asset.selected) {
               selectedAssetID = index;
               found = true;
            }
         }

         if (!found) {
            this.errorMsg = this.lang().PleaseSelectAnAssetFromTheListBelow;
            if (this.selection === "copy") {
               this.alertService.addAlert(this.errorMsg, "warning", 6000);
            }

            return;
         }
      }

      if (
         this.currentLocationID &&
         this.manageLocation.getLocation(this.currentLocationID)?.assetNameUnique == 1
      ) {
         const post = await this.manageAsset.checkAssetNameUnique(
            [this.name],
            this.currentLocationID,
         );
         if (post.data.success == false) {
            this.errorMsg = this.lang().addAssetNameUniqueError;
            this.alertService.addAlert(this.errorMsg, "warning", 6000);
            return;
         }
      }

      //if it made it past all the returns we can successfully close
      const data: {
         asset?: Asset | undefined;
         assetTemplate?: AssetTemplate;
         selection?: CreateAssetSelection;
         locationID: number;
         newName: string;
      } = { locationID: 0, newName: "" };

      if (this.selection === "copy" && selectedAssetID) {
         data.asset = this.manageAsset.getAsset(selectedAssetID);
      } else if (this.selection === "template" && this.selectedTemplate) {
         data.assetTemplate = this.selectedTemplate;
      }

      data.selection = this.selection;
      data.locationID = this.locationID;

      data.newName = this.name;

      this.modalInstance.close(data);
   }

   public runPopAsset = (assetNode: AssetHierarchyNode) => {
      if (!assetNode.assetID) {
         this.alertService.addAlert(this.errorMsg, "warning", 6000);
         return;
      }
      this.manageAsset.popAsset(assetNode.assetID);
   };
}
