<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      @if (showSuccessAssetTemplatesAlert) {
         <lim-ui-alert
            alertType="new-feature"
            [dismissible]="true"
            (dismissedEvent)="dismissSuccessAssetTemplatesAlert()"
            displayMode="banner"
         >
            <div>
               <strong>{{ lang().NewFeature + ": " }}</strong>
               <span>{{ lang().StandardizeYourAssets + " " }}</span>
               <lim-ui-text-button class="link-text lim-ui-fonts-body-extra-bold">
                  <a
                     target="_blank"
                     href="https://help.limblecmms.com/en/articles/9559704"
                     >{{ lang().LearnMore }}
                     <lim-ui-icon icon="arrowUpRightFromSquare" iconSize="small"
                  /></a> </lim-ui-text-button
               >&nbsp;
            </div>
         </lim-ui-alert>
      }
      @if (
         templatesStatus()?.templatesStatus === "required" &&
         selection === "copy" &&
         showInfoAssetTemplatesAlert
      ) {
         <lim-ui-alert
            alertType="info"
            [dismissible]="true"
            (dismissedEvent)="dismissInfoAssetTemplatesAlert()"
            displayMode="banner"
         >
            <div>
               <strong>{{ lang().NewFeature + ": " }}</strong>
               <span>{{ lang().CantFindAnAsset + " " }}</span>
               <lim-ui-text-button class="link-text lim-ui-fonts-body-extra-bold">
                  <a
                     target="_blank"
                     href="https://help.limblecmms.com/en/articles/9559704"
                     >{{ lang().LearnMore }}
                     <lim-ui-icon icon="arrowUpRightFromSquare" iconSize="small"
                  /></a> </lim-ui-text-button
               >&nbsp;
            </div>
         </lim-ui-alert>
      }
      @if (message) {
         <lim-ui-info-panel>
            <span class="message">{{ message }}</span>
         </lim-ui-info-panel>
      }
      <lim-ui-info-panel>
         <div>
            <div class="input-group">
               <div class="input-group-section">
                  <label class="modal-label">{{ lang().NameForTheNewAsset }}</label>
                  <input
                     class="asset-label-input"
                     [(ngModel)]="name"
                     (click)="setDontChangeName()"
                     focusAuto
                     type="text"
                     data-test-id="newAssetModalNameInput"
                  />
               </div>

               @if (currentLocationID === 0) {
                  <div class="input-group-section dropdown-search">
                     <label class="modal-label">{{
                        lang().WhichLocationWouldYouLikeThisAssetToBeAt
                     }}</label>
                     <lim-ui-form-dropdown-input
                        (dropdownItemSelectedEvent)="setLocation($event)"
                        [inputString]="selectedLocationName"
                        class="form-dropdown-input"
                     >
                        <div menu>
                           @if (totalLocLength > 5) {
                              <lim-ui-search-box
                                 [useDropdownStyling]="true"
                                 [searchVal]="searchLocations"
                                 (searchValChange)="runLocationSearch($event)"
                                 [focusOnLoad]="true"
                              />
                           }

                           <lim-ui-dropdown-divider />

                           @for (location of locations; track location) {
                              <lim-ui-dropdown-text-item
                                 [ngClass]="{ dropDownSelectListBorder: !$last }"
                                 [displayString]="location.locationNameWithRegions"
                                 [outputString]="location.locationNameWithRegions"
                                 (itemSelectedEvent)="setLocation(location)"
                              />
                           }
                        </div>
                     </lim-ui-form-dropdown-input>
                  </div>
               }
            </div>
         </div>
      </lim-ui-info-panel>

      <lim-ui-panel>
         @if (newAssetOptionsLoading()) {
            <ngx-skeleton-loader count="1" [theme]="skeletonThemes.regular" />
         } @else {
            <div class="horizotal-panel-content">
               <ng-container radio-button-list groupID="addAnAssetGroup">
                  @if (templatesStatus()?.templatesStatus !== "required") {
                     <lim-ui-radio-button
                        groupID="addAnAssetGroup"
                        [label]="lang().ABlankAsset"
                        [checked]="selection === 'blank'"
                        (click)="selection = 'blank'"
                        [dataLogLabel]="'manageAssets-addAnAsset-clickBlankAsset'"
                        data-test-id="newAssetModalBlankAssetRadio"
                     />
                  }
                  @if (
                     templatesStatus()?.templatesStatus === "optional" ||
                     templatesStatus()?.templatesStatus === "required"
                  ) {
                     <lim-ui-radio-button
                        groupID="addAnAssetGroup"
                        [label]="lang().UseATemplate"
                        [tooltip]="lang().UseATemplateTooltip"
                        [checked]="selection === 'template'"
                        (click)="selection = 'template'; getAssetTemplates()"
                        [dataLogLabel]="'manageAssets-addAnAsset-clickUseATemplate'"
                        data-test-id="newAssetModalUseATemplateRadio"
                     />
                  }
                  <lim-ui-radio-button
                     groupID="addAnAssetGroup"
                     [label]="lang().ACopyOfAnExistingAsset"
                     [tooltip]="lang().ACopyOfAnExistingAssetTooltip"
                     [checked]="selection === 'copy'"
                     (click)="selection = 'copy'; buildCopyAssetList(true)"
                     [dataLogLabel]="'manageAssets-addAnAsset-clickCopyOfExistingAsset'"
                     data-test-id="newAssetModalCopyOfExistingAssetRadio"
                  />
               </ng-container>
            </div>
         }

         @if (selection === "copy") {
            <div class="message horizontal-search-content">
               @if (!noSearchResults) {
                  <span class="pick-an-asset-to-copy-message">
                     {{ lang().PickAnAssetToCopyFromYourLocation }}
                  </span>
               }
               <lim-ui-search-box
                  [(searchVal)]="searchBar"
                  [placeholder]="lang().Search"
                  (searchValChange)="updateSearch()"
                  [focusOnLoad]="true"
                  data-test-id="newAssetModalExistingAssetSearchBox"
               />
            </div>

            <div class="search-results scroll-height-inheritance">
               @if (noSearchResults) {
                  <div>
                     <no-search-results />
                  </div>
               }
               @if (treeData?.length && !noSearchResults) {
                  <hierarchy-container-legacy
                     #hierarchy
                     [treeData]="treeData"
                     [options]="hierarchyOptions"
                     data-test-id="newAssetModalExistingAssetHierarchy"
                  />
               }
            </div>
         }
         @if (selection === "template") {
            @if (assetTemplates(); as assetTemplates) {
               @if (assetTemplates.length === 0) {
                  <div class="message horizontal-search-content">
                     <no-search-results [msg]="lang().TemplatesAreRequired" />
                  </div>
               } @else {
                  <div class="message horizontal-search-content">
                     <span class="pick-an-asset-to-copy-message">
                        {{ lang().PickATemplateToCreateYourAsset }}
                     </span>
                  </div>
                  <div class="search-results scroll-height-inheritance">
                     @for (template of assetTemplates; track template) {
                        <div
                           class="lim-ui-list-item lim-ui-selectable-list-item"
                           (click)="selectedTemplate = template"
                           [class.lim-ui-selected-list-item]="
                              selectedTemplate?.ID === template.ID
                           "
                        >
                           <div class="lim-ui-list-item-content">
                              @if (selectedTemplate?.ID === template.ID) {
                                 <lim-ui-icon
                                    icon="check"
                                    iconSize="small"
                                    iconColor="primary"
                                 />
                              }
                              {{ template.Name }}
                           </div>
                        </div>
                     }
                  </div>
               }
            } @else {
               <lim-ui-loading-animation />
            }
         }
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-basic-modal-footer
      [successButtonText]="lang().Add"
      (successClick)="submit()"
      dataLogSubmitLabel="manageAsset-storeAnAsset"
   />
</lim-ui-modal>
