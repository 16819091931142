import { Component, ChangeDetectionStrategy, inject, output } from "@angular/core";
import { PanelComponent, PrimaryButtonComponent } from "@limblecmms/lim-ui";
import { TranslationService } from "src/app/languages/translation/translation.service";

@Component({
   selector: "feedback-submitted",
   templateUrl: "./feedback-submitted.component.html",
   styleUrls: ["./feedback-submitted.component.scss"],
   changeDetection: ChangeDetectionStrategy.OnPush,
   imports: [PrimaryButtonComponent, PanelComponent],
})
export class FeedbackSubmittedComponent {
   public readonly close = output();

   protected readonly i18n = inject(TranslationService).i18n;
}
