<div class="feedback-submitted">
   <lim-ui-panel>
      <div class="panel-content">
         <div class="header">
            {{ i18n().t("ThanksForYourFeedback") }}
         </div>
         <div class="body">
            {{ i18n().t("YourFeedbackPlaysAKeyRole") }}
         </div>
      </div>
   </lim-ui-panel>
   <div class="actions">
      <lim-ui-primary-button (click)="close.emit()">
         {{ i18n().t("Close") }}
      </lim-ui-primary-button>
   </div>
</div>
