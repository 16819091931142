import { Injectable } from "@angular/core";
import type { Observable } from "rxjs";
import {
   FlannelApiService,
   type RequestOptions,
} from "src/app/shared/services/flannel-api-service";
import type {
   WorkRequestSubmissionEntity,
   WorkRequestSubmissionEntityFilters,
   WorkRequestSubmissionPostRequestBody,
} from "src/app/tasks/components/shared/services/work-request-submissions-api/work-request-submissions-api.models";
import { WorkRequestSubmissionStatus } from "src/app/tasks/services/work-request-submission-view-model-factory/mappers/work-request-submission-view-model-mapper.service";
import { environment } from "src/environments/environment";

@Injectable({
   providedIn: "root",
})
export class WorkRequestSubmissionsApiService extends FlannelApiService<
   WorkRequestSubmissionEntity,
   WorkRequestSubmissionEntityFilters
> {
   private constructor() {
      super("work-request-submissions");
   }

   public async approve(workRequestSubmissionID: number): Promise<boolean> {
      const url = this.taskFlannelWorkRequestSubmissionsUrl();
      const response = await fetch(`${url}/${workRequestSubmissionID}/approve`, {
         method: "POST",
         mode: "cors",
         credentials: "include",
      });
      return response.ok;
   }

   public async decline(
      workRequestSubmissionID: number,
      declineReason: string,
      additionalNotes: string,
   ): Promise<boolean> {
      const url = this.taskFlannelWorkRequestSubmissionsUrl();
      const response = await fetch(`${url}/${workRequestSubmissionID}/decline`, {
         method: "POST",
         mode: "cors",
         credentials: "include",
         body: JSON.stringify({
            declineReason,
            additionalNotes,
         }),
      });
      return response.ok;
   }

   public override getById(
      id: number,
      requestOptions?: Partial<RequestOptions<WorkRequestSubmissionEntityFilters>>,
   ): Observable<WorkRequestSubmissionEntity> {
      const url = this.taskFlannelWorkRequestSubmissionsUrl();

      const finalRequestOptions = {
         ...requestOptions,
         credentials: "include",
         mode: "cors",
         url,
      };

      return super.getById(id, finalRequestOptions, true);
   }

   public override async getTotal(
      requestOptions?: Partial<RequestOptions<WorkRequestSubmissionEntityFilters>>,
   ): Promise<number> {
      const url = this.taskFlannelWorkRequestSubmissionsUrl();
      const newRequestOptions = {
         ...requestOptions,
         url,
      };
      return super.getTotal(newRequestOptions);
   }

   /**
    * NOTE: This method does not work as expected. It creates an infinite request loop.
    * It is kept here for future reference.
    */
   // public override getQueryList(
   //    requestOptions?: Partial<RequestOptions<WorkRequestSubmissionEntityFilters>>,
   // ): Observable<
   //    QueryObserverResult<Partial<ListResponse<WorkRequestSubmissionEntity>>, Error>
   // > {
   //    const url = this.taskFlannelWorkRequestSubmissionsUrl();
   //    const finalRequestOptions = {
   //       ...requestOptions,
   //       withCredentials: "true",
   //       credentials: "include",
   //       mode: "cors",
   //       url,
   //    };
   //    return super.getQueryList(finalRequestOptions);
   // }

   public override getQueryListOptions(
      requestOptions: Partial<RequestOptions<WorkRequestSubmissionEntityFilters>>,
      isActive?: boolean | Promise<boolean>,
   ) {
      const url = this.taskFlannelWorkRequestSubmissionsUrl();

      // we need to default the requestOption statuses to pending and rejected so we can omit approved work requests by default.
      const finalFilters = requestOptions.filters?.statuses
         ? requestOptions.filters
         : ({
              ...requestOptions.filters,
              statuses: [
                 WorkRequestSubmissionStatus.PENDING,
                 WorkRequestSubmissionStatus.REJECTED,
              ],
           } satisfies WorkRequestSubmissionEntityFilters);
      const finalRequestOptions = {
         ...requestOptions,
         filters: finalFilters,
         credentials: "include",
         mode: "cors",
         url,
      };
      return super.getQueryListOptions(finalRequestOptions, isActive, true);
   }

   private taskFlannelWorkRequestSubmissionsUrl() {
      return `${environment.servicesURL()}/tasks/work-request-submissions`;
   }

   public async post(
      workRequestSubmissionPostRequestBody: WorkRequestSubmissionPostRequestBody,
   ): Promise<Response> {
      const url = this.taskFlannelWorkRequestSubmissionsUrl();

      const response = await fetch(url, {
         method: "POST",
         mode: "cors",
         credentials: "include",
         body: JSON.stringify(workRequestSubmissionPostRequestBody),
         headers: {
            "X-Customer-Code":
               workRequestSubmissionPostRequestBody.requestInformation.customerCode,
         },
      });
      if (!response.ok) {
         throw new Error("Failed to submit work request submission", {
            cause: response,
         });
      }
      return response;
   }
}
